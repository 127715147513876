<!-- Copyright 2019-2024 ForgeRock AS. All Rights Reserved

Use of this code requires a commercial software license with ForgeRock AS
or with one of its affiliates. All use shall be exclusively subject
to such license between the licensee and ForgeRock AS. -->
<template>
  <span>
    <FrLayout
      :menu-items="menuItems"
      :realm="realmName"
      :realm-aliases="realmAliases"
      :realm-menu-items="realmMenuItems"
      :tenant-menu-items="tenantMenuItems"
      :release-info="$store.state.tenantReleaseInfo"
      :is-fraas="$store.state.isFraas"
      :is-internal-user="amAdmin">
      <RouterView v-slot="{ Component }">
        <Transition
          name="fade"
          mode="out-in">
          <Component
            :is="Component"
            :key="$route.fullPath" />
        </Transition>
      </RouterView>
    </FrLayout>
    <FrCreateRealmModal />
    <FrSwitchRealmModal />
    <FrAdminInviteModal />
    <FrLoadPromotedAppDataModal />
    <span
      v-if="buildNumber"
      class="d-none">
      {{ buildNumber }}
    </span>
  </span>
</template>

<script>
import { cloneDeep, reject } from 'lodash';
import FrLayout from '@forgerock/platform-shared/src/components/Layout/';
import LoginMixin from '@forgerock/platform-shared/src/mixins/LoginMixin';
import NotificationMixin from '@forgerock/platform-shared/src/mixins/NotificationMixin';
import ValidationRules from '@forgerock/platform-shared/src/utils/validationRules';
import { mapState } from 'pinia';
import { useUserStore } from '@forgerock/platform-shared/src/stores/user';
import { useCustomDomainsStore } from '@/stores/customDomains';
import FrCreateRealmModal from './views/Realm/CreateModal';
import FrSwitchRealmModal from './views/Realm/SwitchModal';
import FrAdminInviteModal from './views/ManagedIdentities/AdminInviteModal';
import FrLoadPromotedAppDataModal from './components/LoadPromotedAppDataModal';
import i18n from '@/i18n';
import './scss/main.scss';
import { getTenantInfo, getTenantReleaseInfo } from '@/api/TenantInfoApi';
import { getLocalLockState, getPairedLockStateForReceiving, getProvisionalReport } from '@/api/FraasPromotionApi';
import { getChangeCountFromReport, getTenantNameFromURL } from '@/utils/promotions';
import { getCustomDomains } from '@/services/customDomainService';

/**
 * @description Controlling component for admin, allows for listing available resources and connects to the create, delete and edit features.
 *
 * @fires GET config/managed - Obtain which managed objects are deployed to this user
 */
export default {
  name: 'App',
  mixins: [
    LoginMixin,
    NotificationMixin,
  ],
  components: {
    FrCreateRealmModal,
    FrSwitchRealmModal,
    FrAdminInviteModal,
    FrLoadPromotedAppDataModal,
    FrLayout,
  },
  data() {
    return {
      menuItems: [],
      buildNumber: process.env.VUE_APP_BUILD_NUMBER,
      realmMenuItems: [],
      tenantMenuItems: [],
    };
  },
  methods: {
    /**
     * Updates the value of an item in a menu item's sub menu (dropdown)
     *
     * @param {string} parentName - display name of the parent menu
     * @param {string} name - display name of the child menu item
     * @param {string} property - property name to update
     * @param {string} value - new value for the property
     */
    setSubMenuItemPropertyByDisplayName(parentName, name, property, value) {
      const parentIndex = this.menuItems.findIndex((item) => (item.displayName && this.$t(item.displayName) === parentName));
      const index = this.menuItems[parentIndex].subItems.findIndex((item) => this.$t(item.displayName) === name);
      this.menuItems[parentIndex].subItems[index][property] = value;
    },
    /**
     * Updates the value of a property in a menu item
     *
     * @param {string} name - display name of the child menu item
     * @param {string} property - property name to update
     * @param {string} value - new value for the property
     */
    setMenuItemPropertyByDisplayName(name, property, value) {
      const index = this.menuItems.findIndex((item) => (item.displayName && this.$t(item.displayName) === name));
      this.menuItems[index][property] = value;
    },
    /**
    * Import the menu json file. The menu file defaults to menus.platform.json
    * but can be overridden using the VUE_APP_MENUS_FILE environment
    * variable. The file extension is included here so webpack can anticipate
    * which files to include.
    */
    async loadMenusFile() {
      try {
        // webpackIgnore is used below to exclude the menus.js file from being
        // minified and bundled by webpack. Instead it will leave the menu file
        // as is, in its original location which allows for customisation.
        let menus = await import(/* webpackIgnore: true */ `../config/menus/${this.$store.state.menusFile}.js`);
        menus = cloneDeep(menus.default);
        this.menuItems = menus.sideMenu;

        if (menus.tenantMenu) {
          this.tenantMenuItems = menus.tenantMenu;
        }
        const nativeConsoleLinks = {
          displayName: 'sideMenu.nativeConsoles',
          icon: 'open_in_new',
          subItems: [],
        };

        // we only want to display the realm menu and native am console links if there is an am url defined
        if (this.$store.state.SharedStore.hasAmUrl) {
          this.realmMenuItems = menus.realmMenu;
          // add the am admin url to the nativeConsoleLinks.subitems
          nativeConsoleLinks.subItems.push({
            displayName: 'sideMenu.accessManagement',
            url: `${this.$store.getters.amPrefix}/dashboard`,
            showForRoles: ['realmAdmin', 'amAdmin'],
          });
        }

        // handle menu items for auto access
        if (this.$store.state.SharedStore.autoAccessEnabled) {
          const autoAccessDashboardMenu = {
            displayName: 'sideMenu.dashboard',
            icon: 'dashboard',
            subItems: [
              {
                displayName: 'sideMenu.realmOverview',
                routeTo: { name: 'Dashboard' },
              },
              {
                displayName: 'sideMenu.risk',
                routeTo: { name: 'AutoAccessRisk' },
              },
              {
                displayName: 'sideMenu.authBehavior',
                routeTo: { name: 'AutoAccessTenantBehavior' },
              },
            ],
          };
          const autoAccessAdminMenu = {
            displayName: 'sideMenu.riskAdministration',
            icon: 'settings',
            subItems: [
              {
                displayName: 'sideMenu.dataSources',
                routeTo: { name: 'AutoAccessDataSources' },
              },
              {
                displayName: 'sideMenu.pipelines',
                routeTo: { name: 'AutoAccessPipelines' },
              },
              {
                displayName: 'sideMenu.riskConfig',
                routeTo: { name: 'AutoAccessRiskConfig' },
              },
            ],
          };
          this.menuItems.splice(0, 1, autoAccessDashboardMenu);
          this.menuItems.splice(8, 0, autoAccessAdminMenu);
        }

        // if nodeDesigner is enabled, the journeys link in the side nav should
        // become a dropdown containing the sub items Journeys and Custom Nodes
        if (this.$store.state.SharedStore.nodeDesignerEnabled) {
          const journeySubItems = [
            {
              routeTo: { name: 'Journeys' },
              displayName: 'sideMenu.journeys',
            },
            {
              routeTo: { name: 'CustomNodes' },
              displayName: 'sideMenu.customNodes',
            },
          ];
          this.setMenuItemPropertyByDisplayName(this.$t('sideMenu.journeys'), 'subItems', journeySubItems);
          this.setMenuItemPropertyByDisplayName(this.$t('sideMenu.journeys'), 'routeTo', null);
        }

        // add the idm admin url to the nativeConsoleLinks.subitems
        nativeConsoleLinks.subItems.push({
          displayName: 'sideMenu.identityManagement',
          url: this.$store.state.realm === 'root' ? this.$store.state.idmAdminURL : `${this.$store.state.idmAdminURL}?realm=${this.$store.state.realm}`,
          showForRoles: ['adminUser'],
        });
        // Add native console links to menuItems
        this.menuItems.push(nativeConsoleLinks);
        // If this isFraas and workforce is not enabled remove the Events menu item
        if (this.$store.state.isFraas && !this.$store.state.SharedStore.workforceEnabled) {
          this.menuItems = reject(this.menuItems, (item) => item.routeTo?.name === 'Events');
        }
      } catch (error) {
        this.showErrorMessage(error, this.$t('application.errors.errorLoadingMenusFile'));
      }
    },
    async loadCustomDomains() {
      const realmName = (this.$store.state.realm === 'root') ? '/' : this.$store.state.realm;
      const customDomains = await getCustomDomains(realmName);
      useCustomDomainsStore().customDomains = customDomains;
    },
  },
  created() {
    // add vee-validate rules
    const rules = ValidationRules.getRules(i18n);
    ValidationRules.extendRules(rules);
  },
  mounted() {
    this.loadCustomDomains();
    this.loadMenusFile();
    if (this.$store.state.isFraas) {
      getTenantReleaseInfo().then(({ data }) => {
        this.$store.commit('setTenantReleaseInfo', data);
      }).catch((error) => {
        this.showErrorMessage(error, this.$t('tenantSettings.details.errors.errorRetrievingReleaseInfo'));
      });

      getTenantInfo().then(({ data }) => {
        this.$store.commit('setTenantInfo', data);

        if (this.$store.state.SharedStore.fraasPromotionUrl) {
          const { state: { SharedStore: { fraasPromotionUrl, fraasPromotionIngressUrl, fraasPromotionEgressUrl } } } = this.$store;
          const currentUrl = fraasPromotionUrl.split('/environment/promotion')[0];
          const currentName = getTenantNameFromURL(currentUrl);
          const lowerName = fraasPromotionIngressUrl ? getTenantNameFromURL(fraasPromotionIngressUrl) : '';
          const upperName = fraasPromotionEgressUrl ? getTenantNameFromURL(fraasPromotionEgressUrl) : '';
          // Set the tier types
          this.$store.commit('setPromotionTenantInfo', {
            currentName,
            currentTier: data.promotionTierInfo?.tierDisplayName,
            upperName,
            upperTier: data.promotionTierInfo?.upperTierDisplayName,
            lowerName,
            lowerTier: data.promotionTierInfo?.lowerTierDisplayName,
          });
        }
      }).then(() => {
        const { currentTier } = this.$store.state.promotionTenantInfo;
        // Load promotion data if we're on a tenant which can promote config
        if (this.$store.state.SharedStore.fraasPromotionEgressUrl) {
          // Load the current pending promotion changes to display in the top right menu
          getProvisionalReport()
            .then(({ data: { report } }) => {
              this.$store.commit('setTenantPromotableChanges', getChangeCountFromReport(report));
            });

          // Check if this tenant is locked to push a promotion - if so, route to the promotion view
          if (currentTier === 'staging' || currentTier === 'uat') {
            // If we're on a staging/UAT tenant, check the lock state of lower and upper (can be done without extra auth).
            // If only upper is locked, it must be to promote to upper environment. Show the promotion view.
            getPairedLockStateForReceiving()
              .then(({ data: { lowerEnv: { state: lowerLockState }, upperEnv: { state: currentLockState } } }) => {
                if (currentLockState === 'locked' && lowerLockState !== 'locked') {
                  this.$router.push('/promotion');
                }
              });
          }
          if (currentTier === 'development') {
            // If we're on a dev/lowest tier tenant, check the lock state.
            // If it is locked, it must be to promote to staging or UAT. Show the promotion view.
            getLocalLockState()
              .then(({ data: { result } }) => {
                if (result === 'locked') {
                  this.$router.push('/promotion');
                }
              });
          }
        }
        // Check for workforce promotion data
        if (this.$store.state.SharedStore.fraasPromotionIngressUrl
          && this.$store.state.SharedStore.workforceEnabled
          && this.$store.state.SharedStore.promoteAppsViaApi === false
          && this.$store.state.tenantInfo.config_promotion_done === true) {
          // Show the load app data modal
          this.$root.$emit('bv::show::modal', 'loadPromotedAppDataModal');
        }
      }).catch((error) => {
        this.showErrorMessage(error, this.$t('tenantSettings.details.errors.errorRetrievingTenantRegion'));
      });
    }
  },
  watch: {
    '$store.state.realm': function stateRealmChanged(val) {
      this.displayNotification('success', this.$t('realm.switchSuccessful', { realmName: val }));

      // Update native consoles AM link
      this.setSubMenuItemPropertyByDisplayName(
        this.$t('sideMenu.nativeConsoles'),
        this.$t('sideMenu.accessManagement'),
        'url',
        `${this.$store.getters.amPrefix}/dashboard`,
      );

      // update realm aliases/custom domains
      this.loadCustomDomains();
    },
  },
  computed: {
    ...mapState(useUserStore, ['amAdmin']),
    realmName() {
      return this.$store.state.realm === 'root' ? this.$t('realm.topLevelRealm') : this.$store.state.realm;
    },
    realmAliases() {
      const aliases = useCustomDomainsStore().customDomains;
      if (aliases.length) {
        return this.$tc('realm.aliasString', aliases.length, { firstAlias: aliases[0], remainingAliases: aliases.length - 1 });
      }
      return `/${this.$store.state.realm}`;
    },
  },
};
</script>
