/**
 * Copyright 2024 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import store from '@/store';
import * as AICApi from '../api/CustomDomainApi';
import * as RealmApi from '../api/RealmApi';

/**
 * Get custom domains for a realm
 * @param {String} realm the realm to get the custom domains for
 * @returns {Promise} Returns a promise which resolves to the custom domains for the realm
 */
export async function getCustomDomains(realm) {
  if (store.state.isFraas) {
    const { data: { domains } } = await AICApi.getCustomDomains(realm);
    return domains;
  }
  const { aliases } = await RealmApi.getRealmConfigByName(realm);
  return aliases;
}

/**
 * Adds a custom domain for a realm
 * @param {String} realm the realm to add the custom domain to
 * @param {String} domain the domain to add
 * @returns {Promise} Returns a promise which resolves to the updated custom domains
 */
export async function addCustomDomain(realm, domain) {
  let updatedDomains;
  if (store.state.isFraas) {
    const { data: { domains: apiDomains } } = await AICApi.getCustomDomains(realm);
    if (!apiDomains.includes(domain)) {
      apiDomains.push(domain);
    }
    const { data: { domains: newApiDomains } } = await AICApi.updateCustomDomains(realm, apiDomains);
    updatedDomains = newApiDomains;
  } else {
    const realmConfig = await RealmApi.getRealmConfigByName(realm);
    if (!realmConfig.aliases.includes(domain)) {
      realmConfig.aliases.push(domain);
    }
    const updatedRealmConfig = await RealmApi.saveRealmConfig(realmConfig);
    updatedDomains = updatedRealmConfig.data.aliases;
  }
  return updatedDomains;
}

/**
 * Deletes a custom domain from a realm
 * @param {String} realm the realm to delete the custom domain from
 * @param {String} domain the domain to delete
 * @returns {Promise} Returns a promise which resolves to the updated custom domains
 */
export async function deleteCustomDomain(realm, domain) {
  let updatedDomains;
  if (store.state.isFraas) {
    const { data: { domains: apiDomains } } = await AICApi.getCustomDomains(realm);
    const index = apiDomains.indexOf(domain);
    if (index !== -1) {
      apiDomains.splice(index, 1);
    }
    const { data: { domains: newApiDomains } } = await AICApi.updateCustomDomains(realm, apiDomains);
    updatedDomains = newApiDomains;
  } else {
    const realmConfig = await RealmApi.getRealmConfigByName(realm);
    const index = realmConfig.aliases.indexOf(domain);
    if (index !== -1) {
      realmConfig.aliases.splice(index, 1);
    }
    const updatedRealmConfig = await RealmApi.saveRealmConfig(realmConfig);
    updatedDomains = updatedRealmConfig.data.aliases;
  }
  return updatedDomains;
}

/**
 * Verifies that a custom domain is valid
 * @param {String} domain the domain to verify
 * @returns {Boolean} true if the domain is valid, false otherwise
 */
export async function isCustomDomainValid(domain) {
  try {
    await AICApi.verifyCustomDomain(domain);
    return true;
  } catch {
    return false;
  }
}
