/**
 * Copyright 2022-2023 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import AppAuthHelper from 'appauthhelper/appAuthHelperCompat';
import SessionCheck from 'oidcsessioncheck';
import { debounce } from 'lodash';
import i18n from '@/i18n';
import {
  lockEnvironments,
  unlockEnvironments,
  getPairedLockStateForPromoting,
} from '@/api/FraasPromotionApi';

export const UPPER_TENANT_AUTH_ID = 'Upper';

/**
 * Gets the number of changes across the different products and product areas of a promotion report
 * @param {Object} promotionReport a report from the promotions API
 * @returns {Number} the number of changes described by the report
 */
export function getChangeCountFromReport(promotionReport) {
  return Object.values(promotionReport)
    .reduce((changeCount, changesForProduct) => (
      changeCount + changesForProduct.reduce((changesForProductCount, changeArea) => {
        let changesForArea = 0;
        Object.values(changeArea.configChange).forEach((changeArray) => {
          changesForArea += changeArray.length;
        });
        return changesForProductCount + changesForArea;
      }, 0)
    ), 0);
}

/**
* Removes 'https' and domain-name info and 'openam-' prefix from a given URL for a more concise name
* @param {string} tenantURL a URL of a tenant
* @return {string} - a tenant name (tenantURL stripped of domain, 'https', and 'openam-' prefix)
*/
export function getTenantNameFromURL(tenantURL) {
  const tenantName = tenantURL.replace('https://', '').replace('openam-', '');
  return tenantName.substring(0, tenantName.indexOf('.')) || tenantName;
}

/**
 * Flattens a promotion report for easier rendering in a table.
 * @example
 * // returns [
 * // { name: 'forgottenusername', category: 'Journeys (Authentication trees)', changeType: 'deleted', realm: '/bravo },
 * // { name: 'test_template', category: 'Email > Templates', changeType: 'added' }
 * // ]
 * flattenPromotionReport({
 *   AMConfig: [
 *     {
 *       configChange: {
 *         deleted: [
 *           { name: 'forgottenusername', realm: '/bravo' },
 *         ],
 *       },
 *       configItem: 'Journeys (Authentication trees)',
 *     },
 *   ],
 *   IDMConfig: [
 *     {
 *       configChange: {
 *         added: [
 *           { name: 'test_template' },
 *         ],
 *       },
 *       configItem: 'Email > Templates',
 *     },
 *   ],
 * });
 * @param {Object} report a report from the promotions API
 * @returns {Array} A flattened array of changes from the report containing data relevant for the UI
 */
export function flattenPromotionReport(report) {
  // Combine the arrays of change categories for AM, IDM, etc.
  const joinedChangesFromProducts = Array.prototype.concat.apply([], Object.values(report));

  return joinedChangesFromProducts.reduce((flattenedReport, changeCategory) => {
    const categoryName = changeCategory.configItem;
    // configChange is an object containing individual config items grouped under keys
    // that describe how they changed eg. { added: [...], deleted: [...] }
    Object.entries(changeCategory.configChange).forEach(([changeType, changes]) => {
      flattenedReport.push(
        // Map each individual change into a new entry in the flattened report,
        // removing the hierarchy of grouping by the type and category of change
        ...changes.map(({ name, realm }) => ({
          name,
          ...(realm ? { realm } : {}),
          category: categoryName,
          changeType,
        })),
      );
    });
    return flattenedReport;
  }, []);
}

/**
 * Determines if the current session is authenticated with the upper promotion tenant
 * @returns {Promise} a Promise that resolves to whether the session is authenticated with the upper tenant
 */
export function checkForUpperTenantAuthentication() {
  return new Promise((resolve) => {
    const request = indexedDB.open('appAuth');
    request.onerror = () => {
      resolve(false);
    };
    request.onsuccess = (event) => {
      // check the appAuth DB for a token to the second environment
      try {
        const db = event.target.result;
        db.transaction(UPPER_TENANT_AUTH_ID).objectStore(UPPER_TENANT_AUTH_ID).getAll().onsuccess = (getEvent) => {
          const tokens = getEvent.target.result;
          resolve(tokens.length);
        };
      } catch (err) {
        resolve(false);
      }
    };
  });
}

/**
 * Adds an AppAuthHelper configuration for authentication with the upper promotion tenant
 * @returns {Promise} a Promise that resolves to whether the config was successful
 */
export function addAppAuthHelperConfigForSecondaryTenant(tenantUrl, tokensAvailableCallback) {
  const clientId = 'idmAdminClient';
  let sessionCheck;
  let debounceMethod;

  return AppAuthHelper
    .init({
      clientId,
      authorizationEndpoint: `${tenantUrl}/am/oauth2/realms/root/authorize`,
      tokenEndpoint: `${tenantUrl}/am/oauth2/realms/root/access_token`,
      revocationEndpoint: `${tenantUrl}/am/oauth2/realms/root/token/revoke`,
      endSessionEndpoint: `${tenantUrl}/am/oauth2/realms/root/connect/endSession`,
      identityProxyPreference: 'XHR',
      authId: UPPER_TENANT_AUTH_ID,
      resourceServers: {
        [`${tenantUrl}/environment/promotion`]: 'fr:idc:promotion:*',
      },
      tokensAvailableHandler: (claims) => {
        sessionCheck = new SessionCheck({
          clientId,
          opUrl: `${tenantUrl}/am/oauth2/realms/root/authorize`,
          authId: UPPER_TENANT_AUTH_ID,
          subject: claims.sub,
          invalidSessionHandler() {
            // Remove old event listeners
            document.removeEventListener('click', debounceMethod);
            document.removeEventListener('keypress', debounceMethod);
            document.removeEventListener('focusin', debounceMethod);

            AppAuthHelper.logout(null, [UPPER_TENANT_AUTH_ID]).then(() => {
              sessionCheck.destroy();
            });
          },
          sessionClaimsHandler(newClaims) {
            if (claims.auth_time !== newClaims.auth_time || claims.realm !== newClaims.realm) {
              this.invalidSessionHandler();
            }
          },
          cooldownPeriod: 5,
        });
        const triggerSession = () => {
          sessionCheck.triggerSessionCheck();
        };

        debounceMethod = debounce(triggerSession, 100);

        // check the validity of the session immediately
        triggerSession();
        if (tokensAvailableCallback) {
          tokensAvailableCallback();
        }

        // Add new event listeners
        document.addEventListener('click', debounceMethod);
        document.addEventListener('keypress', debounceMethod);
        document.addEventListener('focusin', debounceMethod);
      },
    })
    .then(() => {
      AppAuthHelper.getTokens([UPPER_TENANT_AUTH_ID]);
    });
}

/**
 * Polls for the result of an in progress lock operation until the operation completes
 * with either a sucess or error
 * @param {function} successCallback a function to call when the API indicates the environment has locked
 * @param {function} errorCallback a function to call when the API indicates that the lock has failed
 */
export function pollLockState(successCallback, errorCallback) {
  const lockStateInterval = setInterval(() => {
    getPairedLockStateForPromoting()
      .then(({ data: { result: pairedLockState, description } }) => {
        switch (pairedLockState) {
          case 'unlocked':
          case 'unlocking':
          case 'error':
            // lock failed or was undone
            clearInterval(lockStateInterval);
            errorCallback(description);
            break;
          case 'locked':
            // lock completed
            clearInterval(lockStateInterval);
            successCallback();
            break;
          case 'locking':
          default:
            // continue polling
        }
      })
      .catch(() => {
        // continue polling
      });
  }, 11000);
}

/**
 * Initiate locking the tenant and its promotion target for a promotion
 * @returns {Promise} a promise which resolves with the result of the lock operation
 */
export function lockTenants() {
  return new Promise((resolve, reject) => {
    lockEnvironments().then(
      () => {
        pollLockState(resolve, reject);
      },
      (lockInitiatedError) => {
        reject(lockInitiatedError);
      },
    );
  });
}

/**
 * Polls for the result of an in progress unlock operation until the operation completes
 * with either a sucess or error
 * @param {function} successCallback a function to call when the API indicates the environment has unlocked
 * @param {function} errorCallback a function to call when the API indicates that the unlock has failed
 */
export function pollUnlockState(successCallback, errorCallback) {
  const unlockStateInterval = setInterval(() => {
    getPairedLockStateForPromoting()
      .then(({ data: { result: pairedLockState, description } }) => {
        switch (pairedLockState) {
          case 'locked':
          case 'locking':
            // Unlock failed or was undone
            clearInterval(unlockStateInterval);
            errorCallback(description);
            break;
          case 'unlocked':
          case 'error':
            // environment is unlocked
            clearInterval(unlockStateInterval);
            successCallback();
            break;
          case 'unlocking':
          default:
            // continue polling
        }
      })
      .catch(() => {
        // continue polling
      });
  }, 11000);
}

/**
 * Initiate unlocking the tenant and its promotion target
 * @returns {Promise} a promise which resolves with the result of the unlock operation
 */
export function unlockTenants(pendingPromotionId) {
  return new Promise((resolve, reject) => {
    unlockEnvironments(pendingPromotionId).then(
      () => {
        pollUnlockState(resolve, reject);
      },
      (unlockInitiatedError) => {
        reject(unlockInitiatedError);
      },
    );
  });
}

/**
 * Calculates tier name based on passed in tier. If no traslation exists use the actual tier name.
 * @param {string} tier the name of the current tier
 * @returns {string} a translated tier name string or a custom tier name to upper case
 */
export function getTierName(tier) {
  let tn = i18n.global.t(`promotions.tiers.${tier}`);
  // if the translation fails use the tier as is
  if (tn.indexOf('promotions.tiers') === 0) {
    tn = tier.toUpperCase();
  }
  return tn;
}
