<!-- Copyright 2020-2024 ForgeRock AS. All Rights Reserved

Use of this code requires a commercial software license with ForgeRock AS
or with one of its affiliates. All use shall be exclusively subject
to such license between the licensee and ForgeRock AS. -->
<template>
  <VeeForm
    ref="observer"
    v-slot="{ meta: { valid } }"
    as="span">
    <BModal
      id="createRealmModal"
      ref="createRealmModal"
      cancel-variant="link"
      title-class="h5"
      title-tag="h2"
      :ok-disabled="!valid"
      :ok-title="$t('realm.saveRealm')"
      :title="$t('realm.newRealm')"
      @hidden="reset"
      @ok="saveRealm">
      <div>
        <FrField
          validation="required"
          v-model="realmName"
          :label="$t('common.name')" />
        <FrField
          class="my-4"
          type="multiselect"
          v-model="aliases"
          :description="$t('realm.dnsAliasesDescription')"
          :label="$t('realm.dnsAliases')"
          :placeholder="$t('realm.typeToAddDnsAlias')"
          :taggable="true"
          :searchable="true" />
        <FrField
          type="checkbox"
          v-model="clientBasedSessions"
          :description="$t('realm.useClientBasedSessionsDescription')"
          :label="$t('realm.useClientBasedSessions')" />
      </div>
    </BModal>
  </VeeForm>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue';
import { Form as VeeForm } from 'vee-validate';
import FrField from '@forgerock/platform-shared/src/components/Field';
import NotificationMixin from '@forgerock/platform-shared/src/mixins/NotificationMixin/';
import {
  createNewRealm,
  getRealmConfigs,
  setRealmAuthenticationConfig,
  setRealmUrl,
} from '@/api/RealmApi';

export default {
  name: 'CreateRealmModal',
  components: {
    BModal,
    FrField,
    VeeForm,
  },
  mixins: [
    NotificationMixin,
  ],
  data() {
    return {
      realmName: '',
      aliases: [],
      clientBasedSessions: false,
    };
  },
  methods: {
    saveRealm(event) {
      event.preventDefault();

      const newRealm = {
        active: true,
        parentPath: '/',
        name: this.realmName,
        aliases: this.aliases,
      };

      createNewRealm(newRealm).then(() => {
        setRealmAuthenticationConfig(newRealm.name, { statelessSessionsEnabled: this.clientBasedSessions }).then(() => {
          this.updateRealms().then(() => {
            setRealmUrl(newRealm.name);
            this.$store.commit('setRealm', newRealm.name);
            this.$router.push({ name: 'Realm', params: { settingsTab: 'details' } });
            this.$root.$emit('bv::hide::modal', 'createRealmModal');
          });
          this.displayNotification('success', this.$t('realm.successfullyCreated', { realmName: newRealm.name }));
        }, (error) => {
          this.showErrorMessage(error, this.$t('application.errors.errorSavingRealm'));
        });
      }, (error) => {
        this.showErrorMessage(error, this.$t('application.errors.errorSavingRealm'));
      });
    },
    updateRealms() {
      return getRealmConfigs().then(({ data }) => {
        data.result[0].name = 'root';
        this.$store.commit('setRealms', data.result);
      }, (error) => {
        this.showErrorMessage(error, this.$t('application.errors.errorLoadingRealm'));
      });
    },
    reset() {
      this.realmName = '';
      this.aliases = [];
      this.clientBasedSessions = false;
    },
  },
};
</script>

<style lang="scss" scoped>
:deep {
  .multiselect__content {
    display: none !important;
  }
}
</style>
