<!-- Copyright 2020-2024 ForgeRock AS. All Rights Reserved

Use of this code requires a commercial software license with ForgeRock AS
or with one of its affiliates. All use shall be exclusively subject
to such license between the licensee and ForgeRock AS. -->
<template>
  <VeeForm
    ref="observer"
    v-slot="{ meta: { valid } }"
    as="span">
    <BModal
      id="adminInviteModal"
      ref="adminInviteModal"
      no-close-on-backdrop
      no-close-on-esc
      remove-on-delete
      size="lg"
      title-class="h5"
      title-tag="h2"
      :title="modalTitle"
      @ok="inviteAdmins"
      @shown="setFieldError('emailAddresses', undefined, $refs.observer)"
      @hidden="reset">
      <div v-if="isLoading">
        <FrSpinner class="py-5" />
      </div>
      <div
        v-if="inviteResults.length === 0 && !isLoading"
        class="fr-modal-body">
        <FrField
          v-model="emailAddresses"
          type="multiselect"
          validation="required|email"
          class="mb-4 pb-2"
          name="emailAddresses"
          :label="$t('email.inviteAdmin.emailAddresses')"
          :placeholder="$t('email.inviteAdmin.emailAddresses')"
          :searchable="true"
          :taggable="true"
          :validation-immediate="true" />
        <BFormGroup>
          <p>
            {{ $t('email.inviteAdmin.groupRadioHelp') }}
          </p>
          <FrAdminGroupSelection @change-admin-group="changeAdminGroup" />
        </BFormGroup>
      </div>
      <div v-else-if="!isLoading">
        <div>
          <p class="mb-5 text-muted">
            <span v-if="failureResults.length === 0">
              {{ $t('email.inviteAdmin.successCountMessage', { count: emailAddresses.length }) }}
            </span>
            <span v-else-if="failureResults.length < inviteResults.length">
              {{ $t('email.inviteAdmin.partialSuccessMessage', { totalCount: emailAddresses.length, failCount: failureResults.length }) }}
            </span>
            <span v-else>
              {{ $t('email.inviteAdmin.noSuccessMessage', { count: failureResults.length }) }}
            </span>
            <span v-if="$store.state.company && $store.state.company.length > 0">
              {{ $t('email.inviteAdmin.toCompany', { company: $store.state.company }) }}
            </span>
          </p>
        </div>
        <div
          v-if="failureResults.length < inviteResults.length"
          class="mt-3">
          <h3 class="h5">
            {{ $t("email.inviteAdmin.invitedMembers") }}
          </h3>
          <BListGroup
            class="border-bottom mb-5"
            flush>
            <BListGroupItem
              v-for="(result, index) in successResults"
              :key="`${result.email}_${index}_membersInvited`">
              <FrIcon
                icon-class="color-green mr-3"
                name="check_circle">
                {{ result.email }}
              </FrIcon>
            </BListGroupItem>
          </BListGroup>
        </div>
        <div
          v-if="failureResults.length > 0"
          class="mt-3">
          <h3 class="h5">
            {{ $t("email.inviteAdmin.notInvited") }}
          </h3>
          <BListGroup
            class="border-bottom mb-5"
            flush>
            <BListGroupItem
              v-for="(result, index) in failureResults"
              class="d-flex"
              :key="`${result.email}_${index}_membersNotInvited`">
              <span class="flex-grow-1">
                <FrIcon
                  icon-class="color-red mr-3"
                  name="error_outline">
                  {{ result.email }}
                </FrIcon>
              </span>
              <span>
                {{ result.result }}
              </span>
            </BListGroupItem>
          </BListGroup>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel, hide }">
        <div
          v-if="inviteResults.length > 0"
          class="w-100 d-flex justify-content-between">
          <BButton
            class="float-left"
            variant="outline-primary"
            @click="reset()">
            {{ inviteMoreText }}
          </BButton>
          <BButton
            variant="primary"
            @click="hide('done')">
            {{ $t("common.done") }}
          </BButton>
        </div>
        <template v-else>
          <BButton
            variant="link"
            @click="cancel()">
            {{ $t("common.cancel") }}
          </BButton>
          <BButton
            :disabled="!valid || selectedAdminType === null"
            variant="primary"
            @click="ok()">
            {{ $t("email.inviteAdmin.sendInvite") }}
          </BButton>
        </template>
      </template>
    </BModal>
  </VeeForm>
</template>

<script>
import { has } from 'lodash';
import {
  BButton,
  BFormGroup,
  BListGroup,
  BListGroupItem,
  BModal,
} from 'bootstrap-vue';
import dayjs from 'dayjs';
import { Form as VeeForm } from 'vee-validate';
import NotificationMixin from '@forgerock/platform-shared/src/mixins/NotificationMixin/';
import { postManagedResource } from '@forgerock/platform-shared/src/api/ManagedResourceApi';
import { FRAuth, CallbackType } from '@forgerock/javascript-sdk';
import FrField from '@forgerock/platform-shared/src/components/Field';
import FrIcon from '@forgerock/platform-shared/src/components/Icon';
import FrSpinner from '@forgerock/platform-shared/src/components/Spinner/';
import { setFieldError } from '@forgerock/platform-shared/src/utils/veeValidateUtils';
import FrAdminGroupSelection from '../../../components/AdminGroupSelection';

/**
 * Modal that allows admins to invite other admin users
*/
export default {
  name: 'AdminInviteModal',
  components: {
    BButton,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    BModal,
    FrField,
    FrIcon,
    FrSpinner,
    FrAdminGroupSelection,
    VeeForm,
  },
  mixins: [
    NotificationMixin,
  ],
  data() {
    return {
      emailAddresses: [],
      inviteMoreText: this.$t('email.inviteAdmin.inviteMore'),
      inviteResults: [],
      isLoading: false,
      selectedAdminType: null,
    };
  },
  computed: {
    modalTitle() {
      if (this.inviteResults.length === 0) {
        return this.$t('email.inviteAdmin.inviteAdminsTitle');
      }
      if (this.failureResults.length === 0) {
        return this.$t('email.inviteAdmin.invitationSentTitle');
      }
      if (this.failureResults.length < this.inviteResults.length) {
        return this.$t('email.inviteAdmin.partialSuccessTitle');
      }
      return this.$t('email.inviteAdmin.oopsTitle');
    },
    successResults() {
      return this.inviteResults.filter((inviteResult) => inviteResult.result === 'success');
    },
    failureResults() {
      return this.inviteResults.filter((inviteResult) => inviteResult.result !== 'success');
    },
  },
  methods: {
    setFieldError,
    /**
     * Creates teammember/admin managed object and sends email with onboarding link for each email provided
     *
     * @property {object} event Contains click event from confirm button
     */
    inviteAdmins(event) {
      this.isLoading = true;
      this.emailAddresses.forEach((email) => {
        const data = {
          accountStatus: 'Invited',
          inviteDate: dayjs().format('YYYY-MM-DThh:mm:ss').toString(),
          mail: email,
          sn: email,
          givenName: email,
          userName: email,
        };

        data.groups = [this.selectedAdminType];

        postManagedResource(this.$store.state.fraasAdminManagedObjectName, data, false).then(() => {
          const stepParams = {
            realmPath: '/',
            tree: 'AdminOnboarding',
            query: { ForceAuth: 'true' },
          };
          FRAuth.next(undefined, stepParams).then((initStep) => {
            const callback = initStep.getCallbackOfType(CallbackType.StringAttributeInputCallback);
            callback.setValue(email);
            FRAuth.next(initStep, stepParams).then(() => {
              this.inviteResults.push({ email, result: 'success' });
              this.isLoading = this.emailAddresses.length - this.inviteResults.length !== 0;
            }, (error) => {
              this.showErrorMessage(error, this.$t('email.inviteAdmin.errorAdminOnboarding'));
              this.inviteResults.push({ email, result: error });
              this.isLoading = this.emailAddresses.length - this.inviteResults.length !== 0;
              this.inviteMoreText = this.$t('email.inviteAdmin.tryAgain');
            });
          }, (error) => {
            this.showErrorMessage(error, this.$t('email.inviteAdmin.errorAdminOnboarding'));
            this.inviteResults.push({ email, result: error });
            this.isLoading = this.emailAddresses.length - this.inviteResults.length !== 0;
            this.inviteMoreText = this.$t('email.inviteAdmin.tryAgain');
          });
        }, (error) => {
          if (
            has(error, 'response.data.detail.failedPolicyRequirements[0].policyRequirements[0].policyRequirement')
            && error.response.data.detail.failedPolicyRequirements[0].policyRequirements[0].policyRequirement === 'UNIQUE'
          ) {
            this.inviteResults.push({ email, result: this.$t('email.inviteAdmin.errorUnique') });
          } else {
            this.inviteResults.push({ email, result: error });
          }
          this.isLoading = this.emailAddresses.length - this.inviteResults.length !== 0;
          this.inviteMoreText = this.$t('email.inviteAdmin.tryAgain');
        });
      });

      event.preventDefault();
    },
    /**
     * Clears out modal email field, and resets modal display to intial state
     */
    reset() {
      this.selectedAdminType = null;
      this.emailAddresses = [];
      this.inviteResults = [];
      this.inviteMoreText = this.$t('email.inviteAdmin.inviteMore');
    },
    changeAdminGroup(newGroupName) {
      this.selectedAdminType = newGroupName;
    },
  },
};
</script>
<style lang="scss" scoped>
:deep {
  .multiselect__content-wrapper,
  .multiselect__select {
    display: none !important;
  }
}

.fr-modal-body {
  min-height: 180px;
}
</style>
