/**
 * Copyright 2019-2023 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import { generateIdmApi } from '@forgerock/platform-shared/src/api/BaseApi';
import { getConfig, putConfig } from '@forgerock/platform-shared/src/api/ConfigApi';

/**
  * Returns authenticated users basic information information through IDM
  *
  * @returns {Promise}
  */
export function actionAuthenticationLogin() {
  return generateIdmApi().post('/authentication?_action=login');
}

export function getAuthenticationConfig() {
  return getConfig('authentication');
}

export function putAuthenticationConfig(config) {
  return putConfig('authentication', config);
}
