/**
 * Copyright 2020-2021 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS.
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import { find, isNull } from 'lodash';
import { generateAmApi } from '@forgerock/platform-shared/src/api/BaseApi';

const globalConfigRealmApi = {
  path: 'global-config/realms',
  apiVersion: 'protocol=2.0,resource=1.0',
};
/**
  * Returns an object containing an array of realm config objects
  *
  * @returns {Promise}
  */
export function getRealmConfigs() {
  return generateAmApi(globalConfigRealmApi).get(
    '?_queryFilter=true',
    { withCredentials: true },
  );
}
/**
  * Returns an object containing a realm config object
  *
  * @returns {Promise}
  */
export function getRealmConfigByName(realmName) {
  return getRealmConfigs().then((response) => {
    const configs = response.data.result;
    const realmConfig = find(configs, { name: realmName });

    return realmConfig;
  });
}
/**
  * Creates a new realm
  * Returns the newly created realm config
  *
  * @returns {Promise}
  */
export function createNewRealm(config) {
  return generateAmApi(globalConfigRealmApi).post(
    '?_action=create',
    config,
    { withCredentials: true },
  );
}
/**
  * Saves realm details
  * Returns realm config
  *
  * @returns {Promise}
  */
export function saveRealmConfig(config) {
  if (isNull(config.parentPath)) {
    config.parentPath = '';
  }
  return generateAmApi(globalConfigRealmApi).put(
    `/${config._id}`,
    config,
    { withCredentials: true },
  );
}
/**
  * Deletes realm
  * Returns deleted realms config
  *
  * @returns {Promise}
  */
export function deleteRealmConfig(realmId) {
  return generateAmApi(globalConfigRealmApi).delete(
    `/${realmId}`,
    { withCredentials: true },
  );
}
/**
  * Gets realm authentication config
  * Returns realm authentication config
  *
  * @returns {Promise}
  */
export function getRealmAuthenticationConfig(realmName) {
  const realmConfigApi = {
    path: `realms/root/realms/${realmName}/realm-config`,
    apiVersion: 'protocol=1.0,resource=1.0',
  };

  return generateAmApi(realmConfigApi).get(
    '/authentication',
    { withCredentials: true },
  );
}
/**
  * Obtains realm authentication config schema
  * Returns realm authentication config schema
  *
  * @returns {Promise}
  */
export function getRealmAuthenticationSchema(realmName) {
  const realmConfigApi = {
    path: `realms/root/realms/${realmName}/realm-config`,
    apiVersion: 'protocol=1.0,resource=1.0',
  };

  return generateAmApi(realmConfigApi, { withCredentials: true }).post(
    '/authentication?_action=schema',
  );
}
/**
  * Sets the authentication config for a realm
  * Returns realm authentication config
  *
  * @returns {Promise}
  */
export function setRealmAuthenticationConfig(realmName, authenticationConfig) {
  const realmConfigApi = {
    path: `realms/root/realms/${realmName}/realm-config`,
    apiVersion: 'protocol=1.0,resource=1.0',
  };

  return generateAmApi(realmConfigApi).put(
    '/authentication',
    authenticationConfig,
    { withCredentials: true },
  );
}

export const setRealmUrl = (realmName) => {
  const url = new URL(window.location);
  url.searchParams.set('realm', realmName);
  window.history.replaceState(null, null, `${url.origin}${url.pathname}?${url.searchParams.toString()}${url.hash}`);
};
