/**
 * Copyright 2024 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

/* eslint-disable import/prefer-default-export */

import { defineStore } from 'pinia';
import { ref } from 'vue';

/**
 * A store which stores the current custom domains for the realm, to be used in rendering.
 */
export const useCustomDomainsStore = defineStore('customDomains', () => {
  const customDomains = ref([]);

  return {
    customDomains,
  };
});
