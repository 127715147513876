/**
 * Copyright 2021-2024 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS.
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import { generateFraasEnvironmentApi } from '@forgerock/platform-shared/src/api/BaseApi';

/**
 * Get custom domains for a realm
 * @param {String} realm the realm to get the custom domains for
 * @returns {Promise} Returns a promise which resolves to the custom domains for the realm
 */
export function getCustomDomains(realm) {
  return generateFraasEnvironmentApi().get(`/custom-domains/${realm}`);
}

/**
 * Update custom domains for a realm
 * @param {String} realm the realm to update the custom domains for
 * @param {Array} domains an array of custom domains to set
 * @returns {Promise} Returns a promise which resolves to the updated custom domains
 */
export function updateCustomDomains(realm, domains) {
  return generateFraasEnvironmentApi().put(`/custom-domains/${realm}`, { domains });
}

/**
  * Verify a custom domain
  * @param {String} domain the domain to verify
  * @returns {Promise} Returns a promise which resolves successfully for a verified domain, and fails for an unverified domain
  */
export function verifyCustomDomain(domain) {
  return generateFraasEnvironmentApi().post('/custom-domains?_action=verify', { name: domain });
}
