/**
 * Copyright 2019-2024 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';
import i18n from './i18n';
import { setRealmUrl } from '@/api/RealmApi';
import { checkForUpperTenantAuthentication, getChangeCountFromReport } from './utils/promotions';
import { getProvisionalReport } from '@/api/FraasPromotionApi';

function configureRouterHistory() {
  // '&loggedin=true' parameter in the url causes Vue Router to calculate routes incorrectly, as it interprets the parameter as part of the hash.
  // Removing this parameter helps in ensuring that Vue Router computes the routes correctly
  if (window.location.hash.endsWith('&loggedin=true')) {
    window.location.hash = window.location.hash.slice(0, -14);
  }
  return createWebHashHistory();
}

const router = createRouter({
  history: configureRouterHistory(),
  routes: [],
});

router.beforeEach((to, _from, next) => {
  const page = to.name ? i18n.global.t(`pageTitles.${to.name}`) : '';
  document.title = i18n.global.t('pageTitles.pageTitle', { page });
  const realm = new URLSearchParams(window.location.search).get('realm');
  if (!realm) {
    const newRealm = store.state.realm === 'root' && store.state.isFraas ? 'alpha' : store.state.realm;
    store.commit('setRealm', newRealm);
    setRealmUrl(newRealm);
  } else if (realm === '/') {
    const defaultRealm = store.state.isFraas ? 'alpha' : 'root';
    store.commit('setRealm', defaultRealm);
    setRealmUrl(defaultRealm);
  } else if (realm !== store.state.realm) {
    store.commit('setRealm', realm);
  }

  // For promotable tenants, load the current promotion report for display in the top right menu
  if (store.state.SharedStore.fraasPromotionEgressUrl) {
    getProvisionalReport()
      .then(({ data: { report } }) => {
        store.commit('setTenantPromotableChanges', getChangeCountFromReport(report));
      });
  }

  // If the destination route requires cross tenant authentication, check for that and prompt authentication if needed
  if (to.meta.requiresCrossTenantAuth) {
    checkForUpperTenantAuthentication().then((hasUpperAuth) => {
      if (hasUpperAuth) {
        next();
      } else {
        next(`/promotion-auth${to.path}`);
      }
    });
  } else if (window.location.pathname === '/platform/oauthReturn') {
    // Google Connector does not support # in a url- need to convert the redirect URI on return
    // Update the pathname so we don't recursively hit this.
    window.history.replaceState(null, null, window.location.href.replace(window.location.pathname, '/platform/'));
    next({ name: 'OAuthReturn' });
  } else {
    next();
  }
});

export default router;
