<!-- Copyright 2020-2024 ForgeRock AS. All Rights Reserved

Use of this code requires a commercial software license with ForgeRock AS
or with one of its affiliates. All use shall be exclusively subject
to such license between the licensee and ForgeRock AS. -->
<template>
  <BModal
    body-class="p-0"
    id="switchRealmModal"
    ref="switchRealmModal"
    cancel-variant="link"
    ok-only
    ok-variant="link"
    title-class="h5"
    title-tag="h2"
    :ok-title="$t('common.cancel')"
    :title="$t('realm.switchRealmModal')">
    <div
      class="px-1 py-2 border-bottom"
      v-if="showSearch">
      <FrSearchInput
        :value="searchValue"
        :placeholder="$t('realm.searchRealms')"
        @clear="onSearchClear"
        @input="onSearchChange" />
    </div>
    <FrListGroup class="list-group bordered">
      <FrListItem
        :collapsible="false"
        :panel-shown="false"
        :hover-item="false"
        v-if="filteredRealms.length == 0">
        <template #list-item-header>
          <div class="my-2">
            {{ $t('realm.searchRealmsNoMatch') }}
          </div>
        </template>
      </FrListItem>
      <FrListItem
        v-for="realm in displayedRealms"
        :collapsible="false"
        :panel-shown="false"
        :hover-item="true"
        :key="realm._id"
        :class="{'active': realm.name == $store.state.realm}"
        @row-click="switchRealm(realm.name)">
        <template #list-item-header>
          <BMedia
            vertical-align="center"
            no-body
            class="text-left">
            <BMediaAside>
              <div :class="`letter-${realm.name[0]} fr-realm-stamp text-white`">
                {{ realm.name[0].toUpperCase() }}
              </div>
            </BMediaAside>
            <BMediaBody class="sidebar-item-text overflow-hidden">
              <h3 class="my-0 text-truncate h5">
                {{ formatNameTopLevel(realm.name) }}
              </h3>
              <div class="text-truncate">
                <small class="text-muted">
                  {{ realm.path }}
                </small>
              </div>
            </BMediaBody>
          </BMedia>
          <div
            class="text-primary ml-auto d-flex align-items-center"
            v-if="realm.name != $store.state.realm">
            <BButton variant="link">
              {{ $t('realm.switchLink') }}
            </BButton>
          </div>
        </template>
      </FrListItem>
    </FrListGroup>
    <FrPagination
      v-if="filteredRealms.length > perPage"
      v-model="currentPage"
      :dataset-size="DatasetSize.SMALL"
      :total-rows="filteredRealms.length"
      :per-page="perPage"
      @input="updateDisplayedRealms"
    />
  </BModal>
</template>

<script>
import {
  BButton,
  BMedia,
  BMediaAside,
  BMediaBody,
  BModal,
} from 'bootstrap-vue';
import {
  reject,
  sortBy,
} from 'lodash';
import FrListGroup from '@forgerock/platform-shared/src/components/ListGroup/';
import FrListItem from '@forgerock/platform-shared/src/components/ListItem/';
import FrSearchInput from '@forgerock/platform-shared/src/components/SearchInput';
import FrPagination from '@forgerock/platform-shared/src/components/Pagination';
import { DatasetSize } from '@forgerock/platform-shared/src/components/Pagination/types';
import NotificationMixin from '@forgerock/platform-shared/src/mixins/NotificationMixin/';
import RealmMixin from '@forgerock/platform-shared/src/mixins/RealmMixin';
import { setRealmUrl } from '@/api/RealmApi';

export default {
  name: 'SwitchRealmModal',
  components: {
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    BModal,
    FrListGroup,
    FrListItem,
    FrPagination,
    FrSearchInput,
  },
  mixins: [
    NotificationMixin,
    RealmMixin,
  ],
  data() {
    return {
      DatasetSize,
      searchValue: '',
      filteredRealms: [],
      displayedRealms: [],
      realms: [],
      currentPage: 1,
      showSearch: true,
    };
  },
  computed: {
    perPage() {
      return 5;
    },
  },
  methods: {
    switchRealm(realmName) {
      setRealmUrl(realmName);
      this.$store.commit('setRealm', realmName);
      this.$router.push({ name: 'Dashboard' });
      this.$refs.switchRealmModal.hide();
    },
    onSearchClear() {
      this.setSearchValue('');
    },
    onSearchChange(searchValue) {
      this.searchValue = searchValue;
      this.currentPage = 1;
      let query = this.searchValue;

      if (query === '') {
        this.setFilteredRealms(this.realms);
      }
      query = query.toUpperCase();
      const filtered = this.realms.filter((item) => this.formatNameTopLevel(item.name).toUpperCase().match(query));

      this.setFilteredRealms(filtered);
    },
    /**
      * Formats realm paths for displaying to user. The root realm has no
      * parentPath and needs to be displayed as '/'. Subrealms of root, have the
      * parentPath '/' and therefore need to combine '/' and their name. Further
      * subrealms have the parentPath '/realmX' and therefore need to combine
      * the parentPath + '/' + their name.
      */
    formatRealmPaths(realms) {
      realms.forEach((realm) => {
        if (realm.parentPath === null) {
          realm.path = '/';
        } else if (realm.parentPath === '/') {
          realm.path = `${realm.parentPath}${realm.name}`;
        } else {
          realm.path = `${realm.parentPath}/${realm.name}`;
        }
      });
      return realms;
    },
    setFilteredRealms(realms) {
      this.filteredRealms = sortBy(realms, 'path');
      this.updateDisplayedRealms(this.currentPage);
    },
    updateDisplayedRealms(currentPage) {
      const from = (currentPage - 1) * this.perPage;
      const to = from + this.perPage;
      let displayed = this.filteredRealms.slice(from, to);
      // hide the root realm menu option and the search input when in fraas
      if (this.$store.state.isFraas) {
        displayed = reject(displayed, (item) => item.name === '/' || item.name === 'root');
        this.showSearch = false;
      }
      this.displayedRealms = displayed;
    },
    setSearchValue(search) {
      this.searchValue = search;
    },
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'switchRealmModal') {
        const { realms } = this.$store.state;
        this.realms = this.formatRealmPaths(realms);
        this.setFilteredRealms(this.realms);
      }
    });
  },
};
</script>

<style lang="scss">
#switchRealmModal {
  .list-group {
    max-height: 394px;
    overflow-y: auto;
    border-radius: 0;
    margin-bottom: 0 !important;

    .fr-hover-item {
      &:last-child .list-group-item {
        border-bottom: 0;
      }

      &.active {
        pointer-events: none;

        .list-group-item {
          border-left-color: $blue;
          background-color: $light-blue;
        }
      }

      .list-group-item:focus,
      .list-group-item:hover {
        background-color: $light-blue;
      }

      .list-group-item {
        border-radius: 0;
      }
    }

    .list-group-item {
      border-left: 3px solid transparent;
      box-shadow: none;
      border-bottom: 1px solid $gray-200;
    }
  }

  .pagination .page-link {
    margin-left: 0;
  }
}
</style>
