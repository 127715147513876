<!-- Copyright 2022-2023 ForgeRock AS. All Rights Reserved

Use of this code requires a commercial software license with ForgeRock AS
or with one of its affiliates. All use shall be exclusively subject
to such license between the licensee and ForgeRock AS. -->
<template>
  <BModal
    id="loadPromotedAppDataModal"
    hide-header-close
    no-close-on-backdrop
    no-close-on-esc
    ref="loadPromotedAppDataModal"
    scrollable
    title-class="h5"
    title-tag="h2"
    :body-class="[step === 'showList' ? 'p-0' : '']"
    :dialog-class="[loadingData ? 'd-none' : '']"
    :hide-backdrop="loadingData"
    :size="step === 'skipThis' || step === 'dontUpdate' ? 'md' : 'lg'"
    :title="modalTitle"
    @show="loadAndProcessDataFromConfig">
    <template v-if="loadingData">
      <FrSpinner class="py-5" />
    </template>
    <template v-else>
      <div v-if="step === 'initial'">
        <div class="mb-4">
          <p class="text-secondary">
            {{ $t('applications.promotion.chooseDataDescription') }}
          </p>
        </div>
        <div
          class="row">
          <div class="col-md-6">
            <FrCardRadioInput
              :radio-value="true"
              v-model="doUpdate">
              <div class="text-center">
                <div class="mb-3">
                  <span
                    aria-hidden="true"
                    class="material-icons md-48 text-primary">
                    arrow_forward
                  </span>
                </div>
                <div class="media-body fr-mh-44">
                  <h5
                    class="mb-2">
                    {{ $t('applications.promotion.chooseAppUpdates') }}
                  </h5>
                  <p class="text-secondary">
                    {{ $t('applications.promotion.chooseAppUpdatesDescription') }}
                  </p>
                </div>
              </div>
            </FrCardRadioInput>
          </div>
          <div class="col-md-6">
            <FrCardRadioInput
              :radio-value="false"
              v-model="doUpdate">
              <div class="text-center">
                <div class="mb-3">
                  <span
                    aria-hidden="true"
                    class="material-icons md-48">
                    redo
                  </span>
                </div>
                <div class="media-body fr-mh-44">
                  <h5
                    class="mb-2">
                    {{ $t('applications.promotion.dontUpdate') }}
                  </h5>
                  <p class="text-secondary">
                    {{ $t('applications.promotion.dontUpdateDescription') }}
                  </p>
                </div>
              </div>
            </FrCardRadioInput>
          </div>
        </div>
      </div>
      <div v-if="step === 'dontUpdate'">
        <p class="text-secondary">
          {{ $t('applications.promotion.dontUpdateConfirm') }}
        </p>
      </div>
      <div v-if="step === 'skipThis'">
        <p class="text-secondary">
          {{ $t('applications.promotion.skipConfirm') }}
        </p>
      </div>
      <div v-if="step === 'showList'">
        <BTable
          class="mb-0"
          :fields="gridFields"
          :items="applicationsList"
          ref="applicationsTable"
          show-empty
          :empty-text="$t('common.noRecordsToShow')">
          <template #head(checkbox)>
            <FrField
              type="checkbox"
              v-model="checkAllApps"
              name="checkAllApps"
              testid="inp-app-checkAllApps" />
          </template>
          <template #cell(realm)="{ item }">
            <p
              class="mt-1"
              style="text-transform:capitalize;">
              {{ item.realm }}
            </p>
          </template>
          <template #cell(checkbox)="{ item }">
            <FrField
              v-if="item.new"
              type="checkbox"
              v-model="appChoices[item.realm].dynamicAppsNotInCurrentEnv[item.index]"
              :name="`inp-app-${item._id}`"
              :testid="`inp-app-${item._id}`" />
            <FrField
              v-else
              type="checkbox"
              v-model="appChoices[item.realm].dynamicAppsInBothEnvs[item.index]"
              :name="`inp-app-${item._id}`"
              :testid="`inp-app-${item._id}`" />
          </template>
          <template #cell(new)="{ item }">
            <div class="float-left">
              <span
                v-if="item.new"
                class="badge badge-success"
                style="width:100px">
                {{ $t("common.added") }}
              </span>
              <span
                v-else
                class="badge badge-warning"
                style="width:100px">
                {{ $t("common.updated") }}
              </span>
            </div>
          </template>
        </BTable>
      </div>
    </template>
    <template #modal-footer="{ cancel }">
      <div class="w-100">
        <div
          v-if="step === 'showList'"
          class="float-left">
          <BButton
            variant="link"
            @click="step = 'initial'">
            {{ $t('common.previous') }}
          </BButton>
        </div>
        <div class="float-right">
          <BButton
            v-if="step === 'initial' || step === 'showList'"
            variant="link"
            @click="step = 'skipThis'">
            {{ $t('common.skipThis') }}
          </BButton>
          <BButton
            v-if="step === 'initial'"
            variant="primary"
            @click="doUpdate ? step = 'showList' : step = 'dontUpdate'">
            {{ $t('common.next') }}
          </BButton>
          <BButton
            v-if="step === 'skipThis'"
            variant="link"
            @click="step = 'initial'">
            {{ $t('common.cancel') }}
          </BButton>
          <BButton
            v-if="step === 'skipThis'"
            variant="primary"
            @click="cancel()">
            {{ $t('applications.promotion.skipUpdate') }}
          </BButton>
          <BButton
            v-if="step === 'dontUpdate'"
            variant="link"
            class="text-danger"
            @click="step = 'initial'">
            {{ $t('common.cancel') }}
          </BButton>
          <BButton
            v-if="step === 'dontUpdate'"
            variant="danger"
            @click="setAllAppCheckboxes(false); loadAppsIntoCurrentEnv();">
            {{ $t('applications.promotion.dontUpdate') }}
          </BButton>
          <FrButtonWithSpinner
            v-if="step === 'showList'"
            :button-text="$t('applications.promotion.updateApplications')"
            :spinner-text="$t('applications.promotion.updatingApplications')"
            :disabled="loadingAppsIntoCurrentEnv"
            :show-spinner="loadingAppsIntoCurrentEnv"
            @click="loadAppsIntoCurrentEnv"
            testid="btn-modal-save" />
        </div>
      </div>
    </template>
  </BModal>
</template>

<script>
import {
  BButton,
  BModal,
  BTable,
} from 'bootstrap-vue';
import NotificationMixin from '@forgerock/platform-shared/src/mixins/NotificationMixin';
import FrField from '@forgerock/platform-shared/src/components/Field';
import FrButtonWithSpinner from '@forgerock/platform-shared/src/components/ButtonWithSpinner/';
import FrSpinner from '@forgerock/platform-shared/src/components/Spinner/';
import FrCardRadioInput from '@forgerock/platform-shared/src/components/CardRadioInput/';
import {
  appDataContainsApps,
  processPromotedApps,
  retrieveAppDynamicDataFromConfig,
  splitPromotedAppsByChangeType,
} from '@/utils/workforcePromotion';
import { acknowledgePromotion } from '@/api/FraasPromotionApi';

/**
 * A modal which presents users with information on application dynamic data that has been promoted into the current environment.
 * Allows users to select which new apps to load, and whether to overwrite the dynamic data for any apps that already exist in the current tenant.
 * When app data is loaded, the modal lets the promotion API know, so that this option is not presented again until another promotion has run.
 * Can be skipped, and viewed again later.
 */
export default {
  name: 'LoadPromotedAppDataModal',
  mixins: [
    NotificationMixin,
  ],
  components: {
    BButton,
    BModal,
    BTable,
    FrButtonWithSpinner,
    FrField,
    FrSpinner,
    FrCardRadioInput,
  },
  data() {
    return {
      loadingData: true,
      appData: {},
      appsByType: {},
      appChoices: {},
      appSelections: {},
      loadingAppsIntoCurrentEnv: false,
      showList: false,
      checkAllApps: false,
      doUpdate: true,
      step: 'initial',
      gridFields: [
        {
          key: 'checkbox',
          thStyle: { width: '10%' },
        },
        {
          key: 'name',
          label: this.$t('common.name'),
          sortable: true,
        },
        {
          key: 'realm',
          label: this.$t('common.realm'),
          sortable: true,
        },
        {
          key: 'new',
          label: this.$t('common.status'),
          sortable: true,
        }],
    };
  },
  computed: {
    modalTitle() {
      switch (this.step) {
        case 'skipThis':
          return `${this.$t('applications.promotion.skipUpdate')}?`;
        case 'dontUpdate':
          return this.$t('applications.promotion.dontUpdateApplicationData');
        case 'showList':
          return this.$t('applications.promotion.chooseAppsToUpdate');
        default:
          return this.$t('applications.promotion.modalTitle');
      }
    },
    applicationsList() {
      const appList = [];
      ['alpha', 'bravo'].forEach((realm) => {
        ['dynamicAppsNotInCurrentEnv', 'dynamicAppsInBothEnvs'].forEach((appType) => {
          this.appsByType[realm][appType].forEach((item, index) => {
            appList.push({
              _id: item.app._id,
              name: item.app.name,
              realm,
              new: appType === 'dynamicAppsNotInCurrentEnv',
              index,
            });
          });
        });
      });
      return appList;
    },
  },
  watch: {
    checkAllApps(val) {
      this.setAllAppCheckboxes(val);
    },
  },
  methods: {
    closeModal() {
      this.$root.$emit('bv::hide::modal', 'loadPromotedAppDataModal');
    },
    appChoicesExist() {
      const choices = this.appChoices;
      return choices.alpha.dynamicAppsNotInCurrentEnv.length
        || choices.alpha.dynamicAppsInBothEnvs.length
        || choices.bravo.dynamicAppsNotInCurrentEnv.length
        || choices.bravo.dynamicAppsInBothEnvs.length;
    },
    async loadAndProcessDataFromConfig() {
      this.appData = await retrieveAppDynamicDataFromConfig();

      // Close the modal if there's no app data to import
      if (!appDataContainsApps(this.appData)) {
        // Reset the flag for showing this modal so it isn't seen until after another promotion
        acknowledgePromotion();
        this.closeModal();
      }

      this.appsByType = await splitPromotedAppsByChangeType(this.appData);
      // Generate arrays for users to choose which app data to load, defaulting selection to true
      // .fill(true) automatically checks the checkboxes displayed for dynamic apps
      this.appChoices = {
        alpha: {
          dynamicAppsNotInCurrentEnv: new Array(this.appsByType.alpha.dynamicAppsNotInCurrentEnv.length).fill(false),
          dynamicAppsInBothEnvs: new Array(this.appsByType.alpha.dynamicAppsInBothEnvs.length).fill(false),
        },
        bravo: {
          dynamicAppsNotInCurrentEnv: new Array(this.appsByType.bravo.dynamicAppsNotInCurrentEnv.length).fill(false),
          dynamicAppsInBothEnvs: new Array(this.appsByType.bravo.dynamicAppsInBothEnvs.length).fill(false),
        },
      };
      // If there are no dynamic appChoices but there are static apps to process close the modal and process them
      if (!this.appChoicesExist()) {
        this.closeModal();
        await this.loadAppsIntoCurrentEnv();
      } else {
        this.loadingData = false;
      }
    },
    async loadAppsIntoCurrentEnv() {
      this.loadingAppsIntoCurrentEnv = true;
      const dynamicAppDataToLoad = {
        alpha: [
          ...this.appsByType.alpha.dynamicAppsNotInCurrentEnv.filter((v, index) => this.appChoices.alpha.dynamicAppsNotInCurrentEnv[index]),
          ...this.appsByType.alpha.dynamicAppsInBothEnvs.filter((v, index) => this.appChoices.alpha.dynamicAppsInBothEnvs[index]),
        ],
        bravo: [
          ...this.appsByType.bravo.dynamicAppsNotInCurrentEnv.filter((v, index) => this.appChoices.bravo.dynamicAppsNotInCurrentEnv[index]),
          ...this.appsByType.bravo.dynamicAppsInBothEnvs.filter((v, index) => this.appChoices.bravo.dynamicAppsInBothEnvs[index]),
        ],
      };
      const staticAppData = {
        alpha: {
          appsToUpdateOrCreate: this.appsByType.alpha.staticAppsToUpdateOrCreate,
          appsToDelete: this.appsByType.alpha.staticAppsToDelete,
        },
        bravo: {
          appsToUpdateOrCreate: this.appsByType.bravo.staticAppsToUpdateOrCreate,
          appsToDelete: this.appsByType.bravo.staticAppsToDelete,
        },
      };
      try {
        await processPromotedApps(dynamicAppDataToLoad, staticAppData);
        // Reset the flag for showing this modal so it isn't seen until after another promotion
        acknowledgePromotion();
      } catch (error) {
        this.showErrorMessage(error, this.$t('applications.promotion.errorLoadingApps'));
      }

      this.loadingAppsIntoCurrentEnv = false;
      this.closeModal();
    },
    setAllAppCheckboxes(checkAll) {
      this.appChoices.alpha.dynamicAppsNotInCurrentEnv = new Array(this.appsByType.alpha.dynamicAppsNotInCurrentEnv.length).fill(checkAll);
      this.appChoices.bravo.dynamicAppsNotInCurrentEnv = new Array(this.appsByType.bravo.dynamicAppsNotInCurrentEnv.length).fill(checkAll);
      this.appChoices.alpha.dynamicAppsInBothEnvs = new Array(this.appsByType.alpha.dynamicAppsInBothEnvs.length).fill(checkAll);
      this.appChoices.bravo.dynamicAppsInBothEnvs = new Array(this.appsByType.bravo.dynamicAppsInBothEnvs.length).fill(checkAll);
    },
  },
};
</script>

<style lang="scss" scoped>
  li {
    list-style: none;
  }
</style>
