/**
 * Copyright 2021-2024 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import {
  getScriptingContexts,
  getScriptById,
} from '@/api/ScriptApi';

import store from '@/store';

/**
 * Encodes a string as base-64 encoded UTF-8 bytes.
 * See: http://ecmanaut.blogspot.co.uk/2006/07/encoding-decoding-utf8-in-javascript.html
 *
 * @param {String} str the string to encode
 * @returns {String} the base-64 encoded UTF-8 bytes of the string.
 */
export function encodeUTF8(str) {
  return btoa(unescape(encodeURIComponent(str)));
}

/**
 * Decodes a base-64 encoded UTF-8 byte array into a string.
 * See: http://ecmanaut.blogspot.co.uk/2006/07/encoding-decoding-utf8-in-javascript.html
 *
 * @param {String} encoded the base-64 encoded UTF-8 bytes.
 * @returns {String} the decoded string.
 */
export function decodeUTF8(encoded) {
  return decodeURIComponent(escape(atob(encoded)));
}

/**
 * Get the default script data from AM for the passed types
 *
 * @param {Array} types the script types (contexts) to get default script data for
 * @returns {Array} default script data for the passed types, in the order of the passed types
 */
export function getDefaultScriptDataForTypes(types) {
  return getScriptingContexts().then(({ data: { result } }) => types.reduce((defaults, type) => {
    const rawDefaultData = result.find((item) => item._id === type);
    defaults.push({
      context: rawDefaultData._id,
      defaultScriptId: rawDefaultData.defaultScript === '[Empty]' ? undefined : rawDefaultData.defaultScript,
      allowedLanguages: rawDefaultData.languages,
    });
    return defaults;
  }, []));
}

/**
 * Obtains a template script for the passed default script data to use in creation.
 * If AM has a suitable default it will return a new script with the
 * defaults' content, otherwise it will return a blank Javascript script.
 *
 * @param {Object} defaultData the data describing the default script to create a template for
 * @param {Boolean} allowOnlyJavascript whether default scripts in languages other than Javascript are supported
 * @returns {Promise} a promise that resolves to a script template
 */
export function getTemplateScriptForType({ context, defaultScriptId, allowedLanguages }, allowOnlyJavascript = false) {
  const basicTemplate = {
    name: '',
    description: '',
    context,
    language: 'JAVASCRIPT',
    script: '',
    allowedLanguages,
  };

  // If no default script exists for this type, return an empty Javascript template script
  if (!defaultScriptId) {
    return Promise.resolve(basicTemplate);
  }
  // Get the default script for the selected script type
  return getScriptById(defaultScriptId).then(
    ({ data: { script, language } }) => {
      // If the default script language isn't supported, return an empty Javascript template script
      if (language !== 'JAVASCRIPT' && allowOnlyJavascript) {
        return basicTemplate;
      }

      return {
        ...basicTemplate,
        language,
        script,
      };
    },
    // Unable to retrieve the default script (possible if AM mapping config is incorrect), return an empty Javascript template script
    () => basicTemplate,
  );
}

/**
 * Obtains template scripts for the passed types to use in creation.
 *
 * @param {Array} types the script types (contexts) for which a default should be returned
 * @param {Boolean} allowOnlyJavascript whether default scripts in languages other than Javascript are supported
 * @returns {Promise} a promise that resolves to an array of template scripts for the passed types, in the order of the passed types
 */
export function getTemplatesForScriptTypes(types, allowOnlyJavascript = false) {
  return getDefaultScriptDataForTypes(types).then((defaults) => {
    const promises = defaults.map((defaultData) => getTemplateScriptForType(defaultData, allowOnlyJavascript));
    return Promise.all(promises);
  });
}

export const getScriptTypes = () => ({
  AUTHENTICATION_CLIENT_SIDE: {
    legacyContext: 'AUTHENTICATION_CLIENT_SIDE',
  },
  ...(!store.state.isFraas && {
    AUTHENTICATION_SERVER_SIDE: {
      legacyContext: 'AUTHENTICATION_SERVER_SIDE',
    },
  }),
  AUTHENTICATION_TREE_DECISION_NODE: {
    legacyContext: 'AUTHENTICATION_TREE_DECISION_NODE',
    nextGenContext: store.state.SharedStore.scriptBindingsApiEnabled ? 'SCRIPTED_DECISION_NODE' : 'AUTHENTICATION_TREE_DECISION_NODE',
  },
  CONFIG_PROVIDER_NODE: {
    legacyContext: 'CONFIG_PROVIDER_NODE',
  },
  ...(store.state.SharedStore.scriptBindingsApiEnabled && {
    DEVICE_MATCH_NODE: {
      legacyContext: 'AUTHENTICATION_TREE_DECISION_NODE',
      nextGenContext: 'DEVICE_MATCH_NODE',
    },
  }),
  LIBRARY: {
    nextGenContext: 'LIBRARY',
  },
  OAUTH2_ACCESS_TOKEN_MODIFICATION: {
    legacyContext: 'OAUTH2_ACCESS_TOKEN_MODIFICATION',
  },
  OAUTH2_EVALUATE_SCOPE: {
    legacyContext: 'OAUTH2_EVALUATE_SCOPE',
  },
  OAUTH2_MAY_ACT: {
    legacyContext: 'OAUTH2_MAY_ACT',
  },
  OAUTH2_SCRIPTED_JWT_ISSUER: {
    legacyContext: 'OAUTH2_SCRIPTED_JWT_ISSUER',
  },
  OAUTH2_VALIDATE_SCOPE: {
    legacyContext: 'OAUTH2_VALIDATE_SCOPE',
  },
  OIDC_CLAIMS: {
    legacyContext: 'OIDC_CLAIMS',
  },
  POLICY_CONDITION: {
    legacyContext: 'POLICY_CONDITION',
  },
  SAML2_IDP_ADAPTER: {
    legacyContext: 'SAML2_IDP_ADAPTER',
  },
  SAML2_IDP_ATTRIBUTE_MAPPER: {
    legacyContext: 'SAML2_IDP_ATTRIBUTE_MAPPER',
  },
  ...(store.state.SharedStore.extendedSamlConfigEnabled && {
    SAML2_NAMEID_MAPPER: {
      nextGenContext: 'SAML2_NAMEID_MAPPER',
    },
  }),
  SAML2_SP_ADAPTER: {
    legacyContext: 'SAML2_SP_ADAPTER',
  },
  SOCIAL_IDP_PROFILE_TRANSFORMATION: {
    legacyContext: 'SOCIAL_IDP_PROFILE_TRANSFORMATION',
  },
});
