/**
 * Copyright 2021-2024 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import checkIfRouteCanBeAccessed from '@forgerock/platform-shared/src/utils/routerGuard';
import store from '@/store';

export default [
  {
    path: '/',
    redirect: '/dashboard/overview',
  },
  {
    path: '/dashboard/overview',
    name: 'Dashboard',
    component: () => import('../../src/views/Dashboard'),
  },
  {
    path: '/journeys',
    name: 'Journeys',
    component: () => import(/* webpackChunkName: "journeys" */ '../../src/views/Journeys/TreeList'),
  },
  {
    path: '/journeys/:treeId/:returnToTree?',
    name: 'JourneyTree',
    component: () => import(/* webpackChunkName: "journeys" */ '../../src/views/Journeys/TreeBuilder'),
    meta: { hideNavBar: true, hideSideMenu: true },
  },
  {
    path: '/custom-nodes',
    name: 'CustomNodes',
    component: () => import('../../src/views/Journeys/CustomNodes/CustomNodes'),
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.nodeDesignerEnabled]),
  },
  {
    path: '/email/templates',
    name: 'EmailTemplates',
    component: () => import(/* webpackChunkName: "email" */ '../../src/views/Email/Templates'),
  },
  {
    path: '/email/templates/edit/:templateId',
    name: 'EditEmailTemplate',
    component: () => import(/* webpackChunkName: "email" */ '../../src/views/Email/Templates/EditEmailTemplate'),
    meta: { hideNavBar: true, hideSideMenu: true },
  },
  {
    path: '/email/provider',
    name: 'EmailProvider',
    component: () => import(/* webpackChunkName: "email" */ '../../src/views/Email/Provider'),
  },
  {
    path: '/import',
    name: 'BulkImport',
    component: () => import(/* webpackChunkName: "bulkImport" */ '../../src/views/BulkImport'),
  },
  {
    path: '/hosted-pages',
    name: 'HostedPages',
    component: () => import(/* webpackChunkName: "hostedPages" */ '../../src/views/HostedPages'),
  },
  {
    path: '/hosted-pages/:themeName',
    name: 'HostedPagesEdit',
    component: () => import(/* webpackChunkName: "hostedPages" */ '../../src/views/HostedPages/EditHostedPage'),
    meta: { hideNavBar: true, hideSideMenu: true },
  },
  {
    path: '/configure',
    children: [
      {
        path: '',
        name: 'ConfigureManaged',
        component: () => import(/* webpackChunkName: "configure" */ '../../src/views/ConfigureManaged'),
      },
      {
        path: ':managedId',
        name: 'ManagedObjectSettings',
        component: () => import(/* webpackChunkName: "configure" */ '../../src/views/ConfigureManaged/ManagedObjectSettings'),
      },
    ],
  },
  {
    path: '/password-policy/:resourceName?',
    name: 'PasswordPolicy',
    component: () => import(/* webpackChunkName: "passwordPolicy" */ '../../src/views/PasswordPolicy'),
  },
  {
    path: '/security-questions',
    name: 'SecurityQuestions',
    component: () => import(/* webpackChunkName: "securityQuestions" */ '../../src/views/SecurityQuestions'),
    meta: { hideNav: false },
  },
  {
    path: '/connect/:listType?',
    children: [
      {
        path: '',
        name: 'Connect',
        component: () => import(/* webpackChunkName: "connect" */ '../../src/views/Connect'),
      },
      {
        path: 'connector-servers/:serverName/:tab',
        name: 'EditConnectorServer',
        component: () => import(/* webpackChunkName: "connectorServers" */ '../../src/views/Connect/ConnectorServers/EditConnectorServer'),
      },
      {
        path: 'server-clusters/:clusterName/:tab',
        name: 'EditServerCluster',
        component: () => import(/* webpackChunkName: "serverClusters" */ '../../src/views/Connect/ServerClusters/EditServerCluster'),
      },
    ],
  },
  {
    path: '/connect/server-clusters/:clusterName',
    name: 'EditServerClusterServers',
    redirect: '/connect/server-clusters/:clusterName/servers',
  },
  {
    path: '/managed-identities/:resourceType?/:resourceName?',
    children: [
      {
        path: '',
        name: 'ManagedIdentities',
        component: () => import(/* webpackChunkName: "managedIdentities" */ '../../src/views/ManagedIdentities'),
        strict: true,
      },
      {
        path: ':resourceId',
        name: 'EditResource',
        component: () => import(/* webpackChunkName: "managedIdentities" */ '../../src/views/ManagedIdentities/EditResourceView'),
        meta: { authenticate: true, listRoute: 'managed-identities' },
      },
    ],
  },
  {
    path: '/applications',
    children: [
      {
        path: '',
        name: 'Applications',
        component: () => import(/* webpackChunkName: "applications" */ '../../src/views/Applications'),
      },
      {
        path: 'edit/:applicationType/:applicationCategory/:applicationName/:tab/:objectTypeName?/:objectTypeTab?',
        name: 'EditApplication',
        component: () => import(/* webpackChunkName: "applications" */ '../../src/views/Applications/EditApplication'),
      },
      {
        path: 'edit/:applicationType/:applicationCategory/:applicationName/:tab/:objectTypeName/:objectTypeTab/:mappingName',
        beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.workforceAdvancedSyncEnabled]),
        name: 'SyncDataEditor',
        component: () => import(/* webpackChunkName: "applications" */ '../../src/components/ObjectTypeEditor/components/AdvancedSync/SyncDataEdit/SyncDataEditor'),
        meta: { hideNavBar: true, hideSideMenu: true },
      },
    ],
  },
  {
    path: '/edit/:applicationType/:applicationCategory/:applicationName/:tab',
    redirect: '/applications/edit/:applicationType/:applicationCategory/:applicationName/:tab',
  },
  {
    path: '/template-builder',
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.workforceEnabled, store.state.SharedStore.templateBuilderEnabled]),
    children: [
      {
        path: '',
        name: 'TemplateBuilder',
        component: () => import('../../src/views/Applications/TemplateBuilder/TemplateList'),
      },
      {
        path: '',
        name: 'EditTemplateBuilder',
        component: () => import('../../src/views/Applications/TemplateBuilder/TemplateEdit'),
        meta: { hideNavBar: true, hideSideMenu: true },
      },
    ],
  },
  {
    path: '/oauth-clients',
    name: 'OAuthClients',
    component: () => import(/* webpackChunkName: "applications" */ '../../src/views/Applications/ListOAuthClients'),
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.workforceEnabled]),
  },
  {
    path: '/oauthReturn',
    name: 'OAuthReturn',
    component: () => import(/* webpackChunkName: "applications" */ '../../src/views/Applications/EditApplication/Provisioning/ConnectorSettings/GenericConnector/OauthReturn'),
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: () => import('../../src/views/Jobs'),
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.workforceEnabled]),
  },
  {
    path: '/realm/:settingsTab',
    name: 'Realm',
    component: () => import(/* webpackChunkName: "realm" */ '../../src/views/Realm/Settings'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../../src/views/Profile'),
  },
  {
    path: '/auth-devices',
    component: () => import(/* webpackChunkName: "profile" */ '@forgerock/platform-shared/src/components/profile/AuthenticationDevices'),
    meta: {
      authenticate: true,
      hideSideMenu: true,
    },
    props: { forceRoot: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '@forgerock/platform-shared/src/views/NotFound'),
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import(/* webpackChunkName: "forbidden" */ '@forgerock/platform-shared/src/views/Forbidden'),
    meta: { hideSideMenu: true },
  },
  {
    path: '/terms-conditions',
    name: 'TermsAndConditions',
    component: () => import(/* webpackChunkName: "termsandconditions" */ '../../src/views/TermsAndConditions'),
  },
  {
    path: '/terms-conditions/:version',
    name: 'EditTermsAndConditions',
    component: () => import(/* webpackChunkName: "termsandconditions" */ '../../src/views/TermsAndConditions/EditTermsAndConditions'),
    meta: { hideNavBar: true, hideSideMenu: true },
  },
  {
    path: '/gateways-agents',
    children: [
      {
        path: '',
        name: 'GatewaysAndAgents',
        component: () => import(/* webpackChunkName: "gatewaysandagents" */ '../../src/views/GatewaysAndAgents'),
      },
      {
        path: ':type/:id',
        name: 'EditGatewayOrAgent',
        component: () => import(/* webpackChunkName: "gatewaysandagents" */ '../../src/views/GatewaysAndAgents/EditGatewayOrAgent'),
      },
    ],
  },
  {
    path: '/scripts',
    name: 'Scripts',
    component: () => import(/* webpackChunkName: "scripts" */ '../../src/views/Scripts'),
  },
  {
    path: '/customEndpoints',
    name: 'CustomEndpoints',
    component: () => import(/* webpackChunkName: "scripts" */ '../../src/views/CustomEndpoints'),
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import(/* webpackChunkName: "scripts" */ '../../src/views/Events'),
  },
];
