/**
 * Copyright 2021-2024 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import checkIfRouteCanBeAccessed from '@forgerock/platform-shared/src/utils/routerGuard';
import store from '@/store';

export default [
  {
    path: '/',
    redirect: '/dashboard/overview',
  },
  {
    path: '/dashboard/overview',
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('../../src/views/Dashboard'),
      },
      {
        path: 'detail',
        name: 'OverviewDetails',
        component: () => import('../../src/views/Dashboard/Analytics/Journeys/OverviewDetails'),
      },
    ],
  },
  {
    path: '/dashboard/risk',
    children: [
      {
        path: '',
        name: 'AutoAccessRisk',
        component: () => import('@forgerock/platform-shared/src/views/AutoAccess/Activity'),
      },
      {
        path: ':username/normal',
        name: 'AutoAccessUserBehavior',
        component: () => import('@forgerock/platform-shared/src/views/AutoAccess/Activity/NormalView/UserBehavior'),
      },
    ],
  },
  {
    path: '/dashboard/authbehavior',
    name: 'AutoAccessTenantBehavior',
    component: () => import('@forgerock/platform-shared/src/views/AutoAccess/Activity/NormalView/TenantBehavior'),
  },
  {
    path: '/dashboard/data-sources',
    name: 'AutoAccessDataSources',
    component: () => import('@forgerock/platform-shared/src/views/AutoAccess/DataSources'),
  },
  {
    path: '/dashboard/pipelines',
    name: 'AutoAccessPipelines',
    component: () => import('@forgerock/platform-shared/src/views/AutoAccess/Pipelines'),
  },
  {
    path: '/dashboard/risk-config',
    name: 'AutoAccessRiskConfig',
    component: () => import('@forgerock/platform-shared/src/views/AutoAccess/RiskConfig'),
  },
  {
    path: '/certification/:certificationTab?',
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
    children: [
      {
        path: '',
        name: 'Certification',
        component: () => import('../../src/views/Governance/Certification'),
      },
      {
        path: 'campaigns/:campaignId/:tab',
        name: 'CampaignDetails',
        component: () => import('../../src/views/Governance/Certification/Campaigns/CampaignDetails'),
      },
    ],
  },
  {
    path: '/compliance/:complianceTab?',
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
    children: [
      {
        path: '',
        name: 'Compliance',
        component: () => import('../../src/views/Governance/Compliance/Compliance'),
      },
      {
        path: 'policy/:policyId/:policyTab?',
        children: [
          {
            path: '',
            name: 'CompliancePolicyEdit',
            component: () => import('../../src/views/Governance/Compliance/policy/policyEdit/PolicyEdit'),
          },
          {
            path: 'violation/:violationId',
            name: 'CompliancePolicyViolationEdit',
            component: () => import('../../src/views/Governance/Compliance/violation/ViolationEdit'),
          },

        ],
      },
      {
        path: 'policy-rules/:ruleId',
        name: 'ComplianceRuleEdit',
        component: () => import('../../src/views/Governance/Compliance/policyRule/PolicyRuleEdit'),
        meta: { hideNavBar: true, hideSideMenu: true },
      },
      {
        path: 'violation/:violationId',
        name: 'ComplianceViolationEdit',
        component: () => import('../../src/views/Governance/Compliance/violation/ViolationEdit'),
      },
      {
        path: 'exception/:violationId',
        name: 'ComplianceExceptionDetails',
        component: () => import('../../src/views/Governance/Compliance/exception/ExceptionEdit'),
      },
    ],
  },
  {
    path: '/certification/templates/creation/:type?',
    name: 'CertificationTemplate',
    component: () => import('../../src/views/Governance/Certification/Templates/EditTemplate'),
    meta: { hideNavBar: true, hideSideMenu: true },
    // this supplies the route parameter as a prop to the component
    props: true,
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
  },
  {
    path: '/certification/certification-task/:campaignId',
    name: 'CertificationTask',
    component: () => import('@forgerock/platform-shared/src/views/Governance/CertificationTask'),
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
  },
  {
    path: '/certification/campaigns/:campaignId/certification-task',
    name: 'CampaignDetailsTask',
    component: () => import('@forgerock/platform-shared/src/views/Governance/CertificationTask'),
    meta: { hideNavBar: true, hideSideMenu: true },
    props: { isAdmin: true },
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
  },
  {
    path: '/glossary/:glossaryTab?',
    name: 'Glossary',
    component: () => import('../../src/views/Governance/Glossary'),
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
  },
  {
    path: '/governance-events',
    name: 'GovernanceEvents',
    component: () => import('../../src/views/Governance/Events/EventList'),
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
  },
  {
    path: '/requests/:requestsTab?',
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
    children: [
      {
        path: '',
        name: 'GovernanceRequests',
        component: () => import('../../src/views/Governance/requests/Requests'),
      },
      {
        path: 'details/:requestId',
        name: 'RequestDetails',
        component: () => import('../../src/views/Governance/requests/RequestDetails'),
      },
      {
        path: 'request-types/:id',
        name: 'RequestTypeDetails',
        component: () => import('../../src/views/Governance/requests/RequestTypeDetails'),
      },
    ],
  },
  {
    path: '/governance-events/:eventId',
    name: 'GovernanceEventEdit',
    component: () => import('../../src/views/Governance/Events/EventEdit/EventEditManager'),
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
    meta: { hideNavBar: true, hideSideMenu: true },
  },
  {
    path: '/journeys',
    name: 'Journeys',
    component: () => import('../../src/views/Journeys/TreeList'),
  },
  {
    path: '/journeys/:treeId/:returnToTree?',
    name: 'JourneyTree',
    component: () => import('../../src/views/Journeys/TreeBuilder'),
    meta: { hideNavBar: true, hideSideMenu: true },
  },
  {
    path: '/custom-nodes',
    name: 'CustomNodes',
    component: () => import('../../src/views/Journeys/CustomNodes/CustomNodes'),
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.nodeDesignerEnabled]),
  },
  {
    path: '/email/templates',
    name: 'EmailTemplates',
    component: () => import('../../src/views/Email/Templates'),
  },
  {
    path: '/email/templates/edit/:templateId',
    name: 'EditEmailTemplate',
    component: () => import('../../src/views/Email/Templates/EditEmailTemplate'),
    meta: { hideNavBar: true, hideSideMenu: true },
  },
  {
    path: '/email/provider',
    name: 'EmailProvider',
    component: () => import('../../src/views/Email/Provider'),
  },
  {
    path: '/entitlements',
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
    children: [
      {
        path: '',
        name: 'Entitlements',
        component: () => import('../../src/views/Governance/Entitlements'),
      },
      {
        path: ':entitlementId/:tab',
        name: 'EntitlementsDetails',
        component: () => import('../../src/views/Governance/Entitlements/EntitlementsDetails'),
      },
    ],
  },
  {
    path: '/import',
    name: 'BulkImport',
    component: () => import('../../src/views/BulkImport'),
  },
  {
    path: '/hosted-pages',
    name: 'HostedPages',
    component: () => import('../../src/views/HostedPages'),
  },
  {
    path: '/hosted-pages/:themeName',
    name: 'HostedPagesEdit',
    component: () => import('../../src/views/HostedPages/EditHostedPage'),
    meta: { hideNavBar: true, hideSideMenu: true },
  },
  {
    path: '/configure',
    children: [
      {
        path: '',
        name: 'ConfigureManaged',
        component: () => import('../../src/views/ConfigureManaged'),
      },
      {
        path: ':managedId',
        name: 'ManagedObjectSettings',
        component: () => import('../../src/views/ConfigureManaged/ManagedObjectSettings'),
      },
    ],
  },
  {
    path: '/scopes',
    name: 'Scopes',
    component: () => import('../../src/views/Governance/Scopes/ScopeList'),
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
  },
  {
    path: '/scopes/edit/:scopeId?',
    name: 'EditScope',
    component: () => import('../../src/views/Governance/Scopes/EditScope/EditScope'),
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
    meta: { hideNavBar: true, hideSideMenu: true },
  },
  {
    path: '/workflows',
    name: 'Workflows',
    component: () => import('../../src/views/Governance/Workflows/WorkflowList'),
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
  },
  {
    path: '/workflows/:workflowId/:workflowStatus',
    name: 'EditWorkflow',
    component: () => import('../../src/views/Governance/Workflows/WorkflowBuilder'),
    meta: { hideNavBar: true, hideSideMenu: true },
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
  },
  {
    path: '/forms',
    name: 'Forms',
    component: () => import('../../src/views/Governance/Forms/FormsList'),
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
  },
  {
    path: '/forms/:formId',
    name: 'FormEditor',
    component: () => import('../../src/views/Governance/Forms/FormEditor'),
    meta: { hideNavBar: true, hideSideMenu: true },
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.governanceEnabled]),
  },
  {
    path: '/password-policy/:resourceName?',
    name: 'PasswordPolicy',
    component: () => import('../../src/views/PasswordPolicy'),
  },
  {
    path: '/security-questions',
    name: 'SecurityQuestions',
    component: () => import('../../src/views/SecurityQuestions'),
    meta: { hideNav: false },
  },
  {
    path: '/connect/:listType?',
    children: [
      {
        path: '',
        name: 'Connect',
        component: () => import('../../src/views/Connect'),
      },
      {
        path: 'connector-servers/:serverName/:tab',
        name: 'EditConnectorServer',
        component: () => import('../../src/views/Connect/ConnectorServers/EditConnectorServer'),
      },
      {
        path: 'server-clusters/:clusterName/:tab',
        name: 'EditServerCluster',
        component: () => import('../../src/views/Connect/ServerClusters/EditServerCluster'),
      },
    ],
  },
  {
    path: '/connect/server-clusters/:clusterName',
    name: 'EditServerClusterServers',
    redirect: '/connect/server-clusters/:clusterName/servers',
  },
  {
    path: '/managed-identities/:resourceType?/:resourceName?',
    children: [
      {
        path: '',
        name: 'ManagedIdentities',
        component: () => import('../../src/views/ManagedIdentities'),
        strict: true,
      },
      {
        path: ':resourceId',
        name: 'EditResource',
        component: () => import('../../src/views/ManagedIdentities/EditResourceView'),
        meta: { authenticate: true, listRoute: 'managed-identities' },
      },
    ],
  },
  {
    path: '/reports',
    beforeEnter: (_to, _from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.autoReportsEnabled]),
    children: [
      {
        path: '',
        name: 'Reports',
        component: () => import('@forgerock/platform-shared/src/views/Reports/ReportsGrid.vue'),
      },
      {
        path: 'template/:state/:template',
        name: 'EditReportTemplate',
        component: () => import('@forgerock/platform-shared/src/views/Reports/ReportTemplate/ReportTemplate.vue'),
        beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.autoCustomReportsEnabled]),
        meta: { hideNavBar: true, hideSideMenu: true },
      },
      {
        path: 'run/:state/:template',
        name: 'ReportRun',
        component: () => import('@forgerock/platform-shared/src/views/Reports/Report.vue'),
      },
      {
        path: 'history/:state/:template',
        name: 'ReportHistory',
        component: () => import('@forgerock/platform-shared/src/views/Reports/Report.vue'),
      },
      {
        path: 'view/:state/:template/:id',
        name: 'ReportView',
        component: () => import('@forgerock/platform-shared/src/views/Reports/ReportView.vue'),
        meta: { hideNavBar: true, hideSideMenu: true },
      },
    ],
  },
  {
    path: '/applications',
    children: [
      {
        path: '',
        name: 'Applications',
        component: () => import('../../src/views/Applications'),
      },
      {
        path: 'edit/:applicationType/:applicationCategory/:applicationName/:tab/:objectTypeName?/:objectTypeTab?',
        name: 'EditApplication',
        component: () => import(/* webpackChunkName: "applications" */ '../../src/views/Applications/EditApplication'),
      },
      {
        path: 'edit/:applicationType/:applicationCategory/:applicationName/:tab/:objectTypeName/:objectTypeTab/:mappingName',
        beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.workforceAdvancedSyncEnabled]),
        name: 'SyncDataEditor',
        component: () => import(/* webpackChunkName: "applications" */ '../../src/components/ObjectTypeEditor/components/AdvancedSync/SyncDataEdit/SyncDataEditor'),
        meta: { hideNavBar: true, hideSideMenu: true },
      },
    ],
  },
  {
    path: '/edit/:applicationType/:applicationCategory/:applicationName/:tab',
    redirect: '/applications/edit/:applicationType/:applicationCategory/:applicationName/:tab',
  },
  {
    path: '/template-builder',
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.workforceEnabled, store.state.SharedStore.templateBuilderEnabled]),
    children: [
      {
        path: '',
        name: 'TemplateBuilder',
        component: () => import('../../src/views/Applications/TemplateBuilder/TemplateList'),
      },
      {
        path: '',
        name: 'EditTemplateBuilder',
        component: () => import('../../src/views/Applications/TemplateBuilder/TemplateEdit'),
        meta: { hideNavBar: true, hideSideMenu: true },
      },
    ],
  },
  {
    path: '/oauth-clients',
    name: 'OAuthClients',
    component: () => import('../../src/views/Applications/ListOAuthClients'),
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.workforceEnabled]),
  },
  {
    path: '/oauthReturn',
    name: 'OAuthReturn',
    component: () => import('../../src/views/Applications/EditApplication/Provisioning/ConnectorSettings/GenericConnector/OauthReturn'),
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: () => import('../../src/views/Jobs'),
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.workforceEnabled]),
  },
  {
    path: '/realm/:settingsTab',
    name: 'Realm',
    component: () => import('../../src/views/Realm/Settings'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../../src/views/Profile'),
  },
  {
    path: '/auth-devices',
    component: () => import('@forgerock/platform-shared/src/components/profile/AuthenticationDevices'),
    meta: {
      authenticate: true,
      hideSideMenu: true,
    },
    props: { forceRoot: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@forgerock/platform-shared/src/views/NotFound'),
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import('@forgerock/platform-shared/src/views/Forbidden'),
    meta: { hideSideMenu: true },
  },
  {
    path: '/terms-conditions',
    name: 'TermsAndConditions',
    component: () => import('../../src/views/TermsAndConditions'),
  },
  {
    path: '/terms-conditions/:version',
    name: 'EditTermsAndConditions',
    component: () => import('../../src/views/TermsAndConditions/EditTermsAndConditions'),
    meta: { hideNavBar: true, hideSideMenu: true },
  },
  {
    path: '/gateways-agents',
    children: [
      {
        path: '',
        name: 'GatewaysAndAgents',
        component: () => import('../../src/views/GatewaysAndAgents'),
      },
      {
        path: ':type/:id',
        name: 'EditGatewayOrAgent',
        component: () => import('../../src/views/GatewaysAndAgents/EditGatewayOrAgent'),
      },
    ],
  },
  {
    path: '/scripts',
    name: 'Scripts',
    component: () => import('../../src/views/Scripts'),
  },
  {
    path: '/customEndpoints',
    name: 'CustomEndpoints',
    component: () => import('../../src/views/CustomEndpoints'),
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import('../../src/views/Events'),
  },
  {
    path: '/tenant-settings/:resourceName',
    name: 'TenantSettings',
    component: () => import('../../src/views/TenantSettings'),
  },
  {
    path: '/tenant-settings/federation/:providerName',
    name: 'EditProvider',
    component: () => import('../../src/views/TenantSettings/FederationTab/EditProvider'),
  },
  {
    path: '/tenant-admins/:resourceId',
    name: 'EditTenantAdmin',
    component: () => import('../../src/views/EditTenantAdmin'),
  },
  {
    path: '/log-api-keys',
    name: 'LogApiKeys',
    component: () => import('../../src/views/LogApiKeys'),
  },
  {
    path: '/service-accounts',
    name: 'ServiceAccounts',
    component: () => import('../../src/views/ServiceAccounts'),
  },
  {
    path: '/tenant-settings/global-settings/hosted-pages/settings',
    name: 'hostedPagesSettings',
    component: () => import('../../src/views/HostedPages/Settings'),
  },
  {
    path: '/promotion-auth/:destinationPath',
    name: 'PromotionAuthentication',
    component: () => import('../../src/views/PromotionAuthentication'),
    meta: { hideNavBar: true, hideSideMenu: true },
  },
  {
    path: '/lock-tenant',
    name: 'LockTenant',
    component: () => import('../../src/views/LockTenant'),
    meta: { hideSideMenu: true, hideNavBar: true, requiresCrossTenantAuth: true },
  },
  {
    path: '/promotion',
    name: 'Promotion',
    component: () => import('../../src/views/Promotion'),
    meta: { hideNavBar: true, hideSideMenu: true },
  },
  {
    path: '/cookie',
    name: 'Cookie',
    component: () => import('../../src/views/Cookie'),
  },
  {
    path: '/cors',
    name: 'Cors',
    component: () => import('../../src/views/Cors'),
  },
  {
    path: '/content-security-policy/:cspType',
    name: 'Csp',
    component: () => import('../../src/views/Csp/Csp'),
  },
  {
    path: '/ssl-configurations/',
    name: 'SSLConfigurations',
    component: () => import('../../src/views/SSLConfigurations/SSLConfigurations'),
    beforeEnter: (to, from, next) => checkIfRouteCanBeAccessed(next, [store.state.SharedStore.showCertsUi]),
  },
  {
    path: '/ip-addresses',
    name: 'IpAddresses',
    component: () => import('../../src/views/IpAddresses'),
  },
  {
    path: '/esv/:esvType',
    name: 'ESV',
    component: () => import('../../src/views/Esv'),
    meta: { hideNav: false },
  },
  {
    path: '/promotion-history',
    name: 'PromotionHistory',
    component: () => import('../../src/views/PromotionHistory'),
    meta: { requiresCrossTenantAuth: true, showingPushPromotions: true },
  },
  {
    path: '/tenant-updates',
    name: 'TenantUpdates',
    component: () => import('../../src/views/PromotionHistory'),
    meta: { showingPushPromotions: false },
  },
];
