/**
 * Copyright 2022 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import { generateFraasPromotionApi } from '@forgerock/platform-shared/src/api/BaseApi';
import store from '../store';

/**
 * Gets the provisional promotion changes compared to the upper environment
 * @returns {Promise} Returns a Promise that resolves to an object containing an array of log sources
 */
export function getProvisionalReport() {
  return generateFraasPromotionApi().get('/report/provisional');
}

/**
 * Gets the lock state of the current tenant.
 * A tenant can be locked if it is either receiving or sending promotion information.
 * @returns {Promise} Returns a Promise that resolves to an object containing the current lock state
 */
export function getLocalLockState() {
  return generateFraasPromotionApi().get(
    '/lock/state',
    { params: { localLockOnly: true } },
  );
}

/**
 * Gets the lock state of the current tenant and the promotion target
 * @returns {Promise} Returns a Promise that resolves to an object containing the current paired lock state
 */
export function getPairedLockStateForPromoting() {
  return generateFraasPromotionApi().get(`${store.state.SharedStore.fraasPromotionEgressUrl}/environment/promotion/lock/state`);
}

/**
 * Gets the lock state of the current tenant and the lower tenant. This is used for tenants such as staging to help determine the reason
 * for the current lock (the tenant can be locked either to receive or send a promotion).
 * @returns {Promise} Returns a Promise that resolves to an object containing the paired lock state for receiving a promotion
 */
export function getPairedLockStateForReceiving() {
  return generateFraasPromotionApi().get('/lock/state');
}

/**
 * Initiates locking the current tenant and the promotion target
 * @returns {Promise} Returns a Promise that resolves to the status of the lock request
 */
export function lockEnvironments() {
  return generateFraasPromotionApi().post(`${store.state.SharedStore.fraasPromotionEgressUrl}/environment/promotion/lock`);
}

/**
 * Initiates unlocking the current tenant and the promotion target
 * @param {String} promotionId the id of the promotion lock to destroy
 * @returns {Promise} Returns a Promise that resolves to the status of the remove lock request
 */
export function unlockEnvironments(promotionId) {
  return generateFraasPromotionApi().delete(`${store.state.SharedStore.fraasPromotionEgressUrl}/environment/promotion/lock/${promotionId}`);
}

/**
 * Get the status of an in progress promotion
 * @returns {Promise} returns a promise which resolves to the status of the current promotion
 */
export function getPromotionStatus() {
  return generateFraasPromotionApi().get(`${store.state.SharedStore.fraasPromotionEgressUrl}/environment/promotion/promote`);
}

/**
 * Initiates a configuration promotion from the current tenant to the upper tenant
 * @param {String} promoter the name of the promoting admin
 * @param {Boolean} ignoreEncryptedSecrets whether encrypted secrets should be ignored in the promotion
 * @returns {Promise} a promise which resolves to the status of the begin promotion request
 */
export function promoteConfig(promoter, ignoreEncryptedSecrets) {
  return generateFraasPromotionApi().post(
    `${store.state.SharedStore.fraasPromotionEgressUrl}/environment/promotion/promote`,
    {
      dryRun: false,
      unlockEnvironmentsAfterPromotion: true,
      ignoreEncryptedSecrets,
      removeOrphanedConfig: true,
      ...(promoter ? { promoter } : {}),
    },
  );
}

/**
 * Posts a value to set the placeholderManagment and configManagedBy properties on the promotion-placeholders endpoint.
 * @param {String} selectedConfigManagementOption A value that confirms the user's selection on who manges their config, either "CUSTOMER" or "FORGEROCK"
 * @returns {Promise} Returns a Promise that resolves to an object containing placeholderManagement key/value pair
 */
export function setPlaceholderManagementValue(selectedConfigManagementOption) {
  return generateFraasPromotionApi().post('/placeholders',
    {
      placeholderManagement: 'CUSTOMER',
      configManagedBy: selectedConfigManagementOption,
    });
}

/**
 * Gets the report for a promotion from the current tenant to an upper tenant, produces the report for the last promotion if no id is supplied.
 * @returns {Promise} Returns a Promise that resolves to an object containing a report for a promotion from the current tenant to the upper tenant
 */
export function getReportForPushedPromotion(reportId) {
  return generateFraasPromotionApi().get(`${store.state.SharedStore.fraasPromotionEgressUrl}/environment/promotion/report/${reportId}`);
}

/**
 * Gets a list of all promotion report summaries for promotions from the current tenant to the upper tenant.
 * @returns {Promise} Returns a Promise that resolves to an object containing an array of report summaries
 */
export function getReportSummariesForPushedPromotions() {
  return generateFraasPromotionApi().get(`${store.state.SharedStore.fraasPromotionEgressUrl}/environment/promotion/reports`);
}

/**
 * Gets the report for a promotion that the current tenant has received from the lower tenant, produces the report for the last promotion if no id is supplied
 * @param {String} reportId A unique report ID.
 * @returns {Promise} Returns a Promise that resolves to an object containing a report for a promotion to this tenant
 */
export function getReportForReceivedPromotion(reportId = '') {
  return generateFraasPromotionApi().get(`/report/${reportId}`);
}

/**
 * Gets a list of promotion report summaries for promotions that the current tenant has received from the lower tenant.
 * @returns {Promise} Returns a Promise that resolves to an object containing an array of report summaries
 */
export function getReportSummariesForReceivedPromotions() {
  return generateFraasPromotionApi().get('/reports');
}

/**
 * POST to acknowledge that the UI has observed and acted upon a config promotion to the current environment
 * @returns {Promise} Returns a Promise that resolves to the POST response
 */
export function acknowledgePromotion() {
  return generateFraasPromotionApi().post('/internal/ack-promo-done');
}
