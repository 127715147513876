/**
 * Copyright 2019-2024 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import { createStore } from 'vuex';
import Shared from '@forgerock/platform-shared/src/store/modules/Shared';
import AutoAccess from '@forgerock/platform-shared/src/store/modules/AutoAccess';
import getFQDN from '@forgerock/platform-shared/src/utils/getFQDN';
import Analytics from './modules/Analytics';
import i18n from '../i18n';

// returns additional infomation for region:'flag' and 'location'.
const regions = {
  'asia-southeast1': { location: i18n.global.t('locationNames.singapore'), flag: 'sg' },
  'asia-southeast2': { location: i18n.global.t('locationNames.jakarta'), flag: 'id' },
  'australia-southeast1': { location: i18n.global.t('locationNames.sydney'), flag: 'au' },
  'europe-north1': { location: i18n.global.t('locationNames.finland'), flag: 'fi' },
  'europe-west1': { location: i18n.global.t('locationNames.belgium'), flag: 'be' },
  'europe-west2': { location: i18n.global.t('locationNames.london'), flag: 'gb' },
  'europe-west3': { location: i18n.global.t('locationNames.frankfurt'), flag: 'de' },
  'europe-west4': { location: i18n.global.t('locationNames.netherlands'), flag: 'nl' },
  'europe-west6': { location: i18n.global.t('locationNames.zurich'), flag: 'ch' },
  'europe-west9': { location: i18n.global.t('locationNames.paris'), flag: 'fr' },
  'northamerica-northeast1': { location: i18n.global.t('locationNames.montreal'), flag: 'ca' },
  'southamerica-east1': { location: i18n.global.t('locationNames.saoPaulo'), flag: 'br' },
  'us-central1': { location: i18n.global.t('locationNames.iowa'), flag: 'us' },
  'us-east1': { location: i18n.global.t('locationNames.southCarolina'), flag: 'us' },
  'us-east4': { location: i18n.global.t('locationNames.northVirginia'), flag: 'us' },
  'us-west1': { location: i18n.global.t('locationNames.oregon'), flag: 'us' },
  'us-west2': { location: i18n.global.t('locationNames.losAngeles'), flag: 'us' },
  'asia-east2': { location: i18n.global.t('locationNames.hongKong'), flag: 'hk' },
};

export default createStore({
  state: {
    // environment variables
    accessTokenKey: '',
    amAdminURL: null,
    analyticsDashEnabled: false,
    enduserURL: '',
    idmAdminURL: '',
    idmClientID: null,
    idmExportURL: '',
    idmUploadURL: '',
    theme: 'default',
    menusFile: '',
    routesFile: '',

    // realm info
    realm: 'root',
    realms: [],

    // FRaaS specific
    fraasDefaultRealm: '',
    fraasAdminManagedObjectName: '',
    isFraas: false,
    tenant: '',
    tenantInfo: {},
    tenantReleaseInfo: null,
    tenantPromotableChanges: 0,
    promotionTenantInfo: {
      currentName: 'other',
      currentTier: 'other',
    },

    // other
    termsOriginName: '',
  },
  mutations: {
    setEnvironment(state, env) {
      if (env.VUE_APP_ACCESS_TOKEN_KEY) {
        state.accessTokenKey = env.VUE_APP_ACCESS_TOKEN_KEY;
      }

      if (env.VUE_APP_AM_ADMIN_URL) {
        state.amAdminURL = env.VUE_APP_AM_ADMIN_URL;
      }

      if (env.VUE_APP_ENABLE_ANALYTICS_DASH) {
        state.analyticsDashEnabled = env.VUE_APP_ENABLE_ANALYTICS_DASH === 'true';
      }

      if (env.VUE_APP_ENDUSER_URL) {
        state.enduserURL = getFQDN(env.VUE_APP_ENDUSER_URL);
      }

      if (env.VUE_APP_IDM_ADMIN_URL) {
        state.idmAdminURL = getFQDN(env.VUE_APP_IDM_ADMIN_URL);
      }

      if (env.VUE_APP_ADMIN_CLIENT_ID) {
        state.idmClientID = env.VUE_APP_ADMIN_CLIENT_ID;
      }

      if (env.VUE_APP_IDM_EXPORT_URL) {
        state.idmExportURL = getFQDN(env.VUE_APP_IDM_EXPORT_URL);
      }

      if (env.VUE_APP_IDM_UPLOAD_URL) {
        state.idmUploadURL = getFQDN(env.VUE_APP_IDM_UPLOAD_URL);
      }

      if (env.THEME) {
        state.theme = env.THEME;
      }

      if (env.VUE_APP_FRAAS === 'true') {
        state.isFraas = true;

        if (env.VUE_APP_FRAAS_DEFAULT_REALM) {
          state.fraasDefaultRealm = env.VUE_APP_FRAAS_DEFAULT_REALM;
        }
        if (env.VUE_APP_FRAAS_ADMIN_MANAGED_OBJECT_NAME) {
          state.fraasAdminManagedObjectName = env.VUE_APP_FRAAS_ADMIN_MANAGED_OBJECT_NAME;
        }

        // eslint-disable-next-line no-control-regex
        const tenantFromUrlReg = new RegExp('^(?:https?://)?(?:[^@/\n]+@)?(?:www.)?([^:/?\n]+)');
        // eslint-disable-next-line prefer-destructuring
        state.tenant = state.idmAdminURL.match(tenantFromUrlReg)[1];
      }

      if (window.Cypress && (window.Cypress.env('MENUS_FILE') !== undefined)) {
        state.menusFile = window.Cypress.env('MENUS_FILE');
      } else if (env.VUE_APP_MENUS_FILE) {
        state.menusFile = env.VUE_APP_MENUS_FILE;
      } else if (env.VUE_APP_FRAAS === 'true') {
        state.menusFile = 'menus.idcloud';
      } else {
        state.menusFile = 'menus.platform';
      }

      if (window.Cypress && (window.Cypress.env('ROUTES_FILE') !== undefined)) {
        state.routesFile = window.Cypress.env('ROUTES_FILE');
      } else if (env.VUE_APP_ROUTES_FILE) {
        state.routesFile = env.VUE_APP_ROUTES_FILE;
      } else if (env.VUE_APP_FRAAS === 'true') {
        state.routesFile = 'routes.idcloud';
      } else {
        state.routesFile = 'routes.platform';
      }
    },
    setRealm(state, realm) {
      const realmName = (realm.startsWith('/')) ? realm.substring(1) : realm;
      state.realm = realmName;
    },
    setRealms(state, realms) {
      state.realms = realms;
    },
    setTenantInfo(state, tenantInfo) {
      state.tenantInfo = tenantInfo;
    },
    setTenantReleaseInfo(state, releaseInfo) {
      state.tenantReleaseInfo = releaseInfo;
    },
    setTenantPromotableChanges(state, promotableChanges) {
      state.tenantPromotableChanges = promotableChanges;
    },
    setPromotionTenantInfo(state, promotionTenantInfo) {
      state.promotionTenantInfo = promotionTenantInfo;
    },
    setTermsOriginName(state, originName) {
      state.termsOriginName = originName;
    },
  },
  getters: {
    amPrefix: (state) => {
      const originalLoginRealm = localStorage.getItem('originalLoginRealm');
      const realmPath = (!originalLoginRealm || originalLoginRealm === 'root' || state.isFraas) ? '' : `?realm=${originalLoginRealm}`;
      return (state.realm === 'root' ? `${state.amAdminURL}#realms/%2F` : `${state.amAdminURL}${realmPath}#realms/%2F${state.realm}`);
    },
    hasTenantInfo: (state) => (Object.keys(state.tenantInfo).length > 0),
    realm: (state) => state.realm,
    tenantRegionInfo: (state) => regions[state.tenantInfo.region],
    tenantBackupRegionInfo: (state) => regions[state.tenantInfo.backupRegion],
  },
  modules: {
    ...AutoAccess,
    AnalyticsStore: {
      namespaced: true,
      actions: Analytics.actions,
      state: Analytics.state,
      getters: Analytics.getters,
      mutations: Analytics.mutations,
    },
    SharedStore: {
      namespaced: true,
      state: Shared.state,
      mutations: Shared.mutations,
    },
  },
});
