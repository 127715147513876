/**
 * Copyright 2022-2024 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import { cloneDeep } from 'lodash';
import { generateAmApi } from '@forgerock/platform-shared/src/api/BaseApi';
import axios from 'axios';
import apiUtils from '@forgerock/platform-shared/src/api/utils/apiUtils';
import store from '@/store';
import { encodeUTF8 } from '@/views/Scripts/ScriptUtility/ScriptUtility';

const apiVersion = 'protocol=2.0,resource=1.0';
const saml2ApiRequest = (realm) => {
  const configPath = realm?.length
    ? apiUtils.getRealmConfigPath(realm) : apiUtils.getCurrentRealmConfigPath();
  const requestConfig = {
    path: `${configPath}/saml2`,
    apiVersion,
  };
  return generateAmApi(requestConfig, { timeout: 30000 });
};

/**
   * Gets saml2 hosted schema
   * @returns {Promise} a promise that resolves to saml2 hosted schema
   */
export function getSaml2Schema(location) {
  return saml2ApiRequest().post(
    `/${location}?_action=schema`,
    { withCredentials: true },
  );
}

/**
   * Gets all saml2 entities
   * @param {string} location location either hosted or remote
   * @returns {Promise} a promise that resolves to an array of saml2 entity objects
   */
export function getAllSaml2Entities() {
  return saml2ApiRequest().get(
    '?_queryFilter=true',
    { withCredentials: true },
  );
}

/**
   * Gets saml2 entity list entry
   * @param {string} entityId saml2 entityId
   * @returns {Promise} a promise that resolves to an array of saml2 list with private ids
   */
export function getSaml2PrivateId(entityId) {
  return saml2ApiRequest().get(
    `?_queryFilter=entityId+eq+'${entityId}'`,
    { withCredentials: true },
  ).then(({ data }) => {
    if (data.result.length > 0) {
      return data.result[0]._id;
    }
    return Promise.reject(new Error('No matching Entity'));
  });
}

/**
   * Gets a remote saml2 entity's xml representation for the passed ID.
   * @param {string} entityId saml2 entity id
   * @returns {Promise} a promise that resolves to an object containing a saml2 entity xml representation
   */
export function getRemoteSaml2EntityXML(entityId) {
  const realmName = (store.state.realm === 'root') ? '/' : store.state.realm;
  const requestOverride = {
    baseURL: `${store.state.SharedStore.amBaseURL}`,
    apiVersion,
  };
  return generateAmApi({}, requestOverride).get(
    `/saml2/jsp/exportmetadata.jsp?entityid=${encodeURIComponent(entityId)}&realm=${realmName}`,
    { withCredentials: true },
  );
}

export function getHostedSaml2EntityXML(entityId) {
  const realmName = (store.state.realm === 'root') ? '/' : store.state.realm;
  const requestUrl = `${store.state.SharedStore.amBaseURL}/saml2/jsp/exportmetadata.jsp?entityid=${entityId}&realm=${realmName}`;
  return axios.get(requestUrl, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
}
/**
   * Gets a saml2 entity for the passed ID.
   * @param {string} privateId saml2 entity id
   * @param {string} entityLocation location of the saml2Config
   * @param {boolean} xml export remote as xml
   * @param {String} realm the realm to get query in
   * @returns {Promise} a promise that resolves to an object containing a saml2 entity
   */
// TODO: maybe this shouldn't default to xml = true, should probably be a new renamed function
export function getSaml2EntityByIdAndLocation(privateId, entityLocation, xml = true, realm) {
  return saml2ApiRequest(realm).get(
    `/${entityLocation}/${privateId}`,
    { withCredentials: true },
  ).then((entityResponse) => {
    entityResponse.data._id = privateId;
    /**
       * Adding entityLocation here to the entityResponse because the import tool
       * needs to know whether the saml2 entity is remote or not (this will be removed
       * from the config before importing see updateSaml2Entity and createSaml2Entity functions).
       * Importing a remote saml2 entity is a slightly different request (see createSaml2Entity).
       */
    entityResponse.data.entityLocation = entityLocation;

    if (entityLocation === 'remote' && xml) {
      // get the xml representation of this entity and add it to the entityResponse;
      return getRemoteSaml2EntityXML(entityResponse.data.entityId).then((entityXmlResponse) => {
        // eslint-disable-next-line no-useless-escape
        entityResponse.data.base64EntityXML = encodeUTF8(entityXmlResponse.data).replace(/\+/g, '-').replace(/\//g, '_').replace(/\=+$/, '');
        return entityResponse;
      });
    }

    return entityResponse;
  });
}
/**
  * Creates a saml2 entity
  * @param {Object} saml2EntityConfig the saml2 entity to create
  * @param {String} realm the realm to create the saml2 entity in
  * @returns {Promise}
  */
export function createSaml2Entity(saml2EntityConfig, realm) {
  let requestPath = `/${saml2EntityConfig.entityLocation}/`;
  let postData = cloneDeep(saml2EntityConfig);
  delete postData.entityLocation;
  delete postData.base64EntityXML;

  if (saml2EntityConfig.entityLocation === 'remote') {
    /**
      * The only way to do a create on a remote saml2 enity is to use _action=importIdentity
      * and post an xml representation of the configuration.
      */
    requestPath = `${requestPath}?_action=importEntity`;
    postData = {
      standardMetadata: saml2EntityConfig.base64EntityXML,
    };
  } else {
    requestPath = `${requestPath}?_action=create`;
  }

  return saml2ApiRequest(realm).post(
    requestPath,
    postData,
    { withCredentials: true },
  );
}
/**
   * Updates a saml2 entity for the passed ID.
   * @param {string} entityId saml2 entity id
   * @param {string} entityLocation location of the saml2Config
   * @param {Object} saml2EntityConfig the saml2 entity to update
   * @param {String} realm the realm to perform the update in
   * @returns {Promise} a promise that resolves to an object containing a saml2 entity
   */
export function updateSaml2Entity(entityId, entityLocation, saml2EntityConfig, realm) {
  const postData = cloneDeep(saml2EntityConfig);
  delete postData.entityLocation;
  delete postData.base64EntityXML;

  return saml2ApiRequest(realm).put(
    `/${entityLocation}/${entityId}`,
    postData,
    { withCredentials: true },
  );
}

/**
   * Updates a saml2 entity for the passed ID.
   * @param {string} privateId saml2 entity id
   * @param {string} entityLocation location of the saml2Config
   * @returns {Promise} a promise that resolves to an object containing a saml2 entity
   */
export function deleteSaml2Entity(entityId, entityLocation) {
  return saml2ApiRequest().delete(
    `/${entityLocation}/${entityId}`,
    { withCredentials: true },
  );
}
