<!-- Copyright 2023-2024 ForgeRock AS. All Rights Reserved

Use of this code requires a commercial software license with ForgeRock AS
or with one of its affiliates. All use shall be exclusively subject
to such license between the licensee and ForgeRock AS. -->
<template>
  <BRow
    id="adminGroupSelection"
    role="radiogroup">
    <template
      v-for="(group, index) in adminGroups"
      :key="index">
      <div class="col-12 col-lg-6 mb-4">
        <FrCardRadioInput
          name="selectedAdminGroup"
          :radio-value="group.name"
          @change="setGroup"
          v-model="selectedAdminGroup">
          <BMedia
            no-body>
            <div
              :class="`
                fr-icon-container
                d-flex
                align-items-center
                justify-content-center
                p-3
                mr-4
                rounded
                border
                border-${group.iconColor}`">
              <FrIcon
                :icon-class="`color-${group.iconColor} md-24`"
                :name="group.icon" />
            </div>
            <BMediaBody>
              <h5>
                {{ group.title }}
              </h5>
              <p class="mb-0">
                {{ group.description }}
              </p>
            </BMediaBody>
          </BMedia>
        </FrCardRadioInput>
      </div>
    </template>
  </BRow>
</template>

<script>
import {
  BMedia,
  BMediaBody,
  BRow,
} from 'bootstrap-vue';
import FrIcon from '@forgerock/platform-shared/src/components/Icon';
import FrCardRadioInput from '@forgerock/platform-shared/src/components/CardRadioInput/';
/**
 * Component for choosing admin type
 * Options are `super-admins` and `tenant-admins`
*/
export default {
  name: 'AdminGroupSelection',
  components: {
    BMedia,
    BMediaBody,
    BRow,
    FrIcon,
    FrCardRadioInput,
  },
  props: {
    initialAdminGroup: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedAdminGroup: this.initialAdminGroup,
      adminGroups: [
        {
          name: 'super-admins',
          icon: 'manage_accounts',
          iconColor: 'green',
          title: this.$t('email.inviteAdmin.superAdmin'),
          description: this.$t('email.inviteAdmin.superAdminsText'),
        },
        {
          name: 'tenant-admins',
          icon: 'supervisor_account',
          iconColor: 'blue',
          title: this.$t('tenantSettings.federation.tenantAdmin'),
          description: this.$t('email.inviteAdmin.tenantAdminsText'),
        },
      ],
    };
  },
  methods: {
    setGroup(value) {
      this.$emit('change-admin-group', value);
    },
  },
};
</script>
  <style lang="scss" scoped>
    .fr-icon-container {
      width: 42px;
      height: 42px;
    }
</style>
