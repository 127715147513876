/**
 * Copyright 2023 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import { getManagedResource } from '@forgerock/platform-shared/src/api/ManagedResourceApi';

/**
 * Admin group types
 */
export const FEDERATION_ADMIN_GROUPS = ['super-admins', 'tenant-admins'];

/**
 * Determines whether the team members group attribute indicates they are a member of the specified group
 * @param {Array} teamMemberGroups an array of team member groups
 * @param {String} group the name of the group to check for
 * @returns {Boolean} whether or not the group is present in the groups array
 */
export function adminGroupsContainsGroup(teamMemberGroups, group) {
  return teamMemberGroups && teamMemberGroups.includes(group);
}

/**
 * Determines the privileges of the current admin
 * Only super admins can manage other tenant admins' sign on
 * * @param {String} id teammember id
 */
export async function determineAdminPrivileges(id) {
  const { data: { groups } } = await getManagedResource('teammember', id);
  return {
    FederationAdmin: adminGroupsContainsGroup(groups, 'super-admins'),
  };
}
