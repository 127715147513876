/**
 * Copyright 2019-2024 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import axios from 'axios';
import { createApp } from 'vue';
import Vue3Sanitize from 'vue-3-sanitize';
import { Config } from '@forgerock/javascript-sdk';
import Notifications, { notify } from '@kyvg/vue3-notification';
import SessionCheck from 'oidcsessioncheck';
import parseSub from '@forgerock/platform-shared/src/utils/OIDC';
import { debounce } from 'lodash';
import AppAuthHelper from 'appauthhelper/appAuthHelperCompat';
import { generateAmApi } from '@forgerock/platform-shared/src/api/BaseApi';
import { getManagedResource } from '@forgerock/platform-shared/src/api/ManagedResourceApi';
import { getUiConfig } from '@forgerock/platform-shared/src/api/ConfigApi';
import getFQDN from '@forgerock/platform-shared/src/utils/getFQDN';
import { setLocales, overrideTranslations } from '@forgerock/platform-shared/src/utils/overrideTranslations';
import { baseSanitizerConfig } from '@forgerock/platform-shared/src/utils/sanitizerConfig';
import { createPinia } from 'pinia';
import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm.min';
import { useUserStore } from '@forgerock/platform-shared/src/stores/user';
import { getAllLocales } from '@forgerock/platform-shared/src/utils/locale';
import { JAVASCRIPT_SDK_TIMEOUT } from '@forgerock/platform-shared/src/utils/constants';
import { actionAuthenticationLogin } from '@/api/AuthenticationApi';
import { setRealmUrl, getRealmConfigs } from '@/api/RealmApi';
import { checkForUpperTenantAuthentication, addAppAuthHelperConfigForSecondaryTenant } from '@/utils/promotions';
import { determineAdminPrivileges } from '@/utils/adminPrivileges';
import store from '@/store';
import App from './App';
import i18n from './i18n';
import router from './router';

const pinia = createPinia();

const HAS_AM_URL = process.env.VUE_APP_AM_URL && process.env.VUE_APP_AM_URL.length > 0;

function getUserInfo(sessionId) {
  return new Promise((resolve) => {
    if (HAS_AM_URL) {
      const urlParams = new URLSearchParams(window.location.search);

      // User information should always come from root for Fraas environments
      const realm = store.state.isFraas ? 'root' : localStorage.getItem('originalLoginRealm') || urlParams.get('realm');
      const path = realm.length > 1 ? `realms/root/realms/${realm}/users` : 'users';
      generateAmApi({
        path,
        apiVersion: 'protocol=2.1,resource=1.0',
      }).get(`/${sessionId}`, { withCredentials: true }).then((response) => {
        resolve(response);
      });
    } else {
      resolve({
        data: {
          roles: [],
        },
      });
    }
  });
}

// Router guard to check authenticated routes
router.beforeEach((to, from, next) => {
  const userStore = useUserStore(pinia);
  // Retrieve user data to check if the user can access this resource and for future role checks
  if (userStore.userId === '') {
    actionAuthenticationLogin().then((authentication) => {
      const { data: { authenticationId, authorization: { roles: idmRoles, component } } } = authentication;
      userStore.userId = authenticationId;
      userStore.idmRoles = idmRoles;

      // If this is an internal managed user then there is no point in making validation
      if (component === 'internal/user') {
        // Initialise user data to generic admin information
        userStore.setInternalUserDetails();

        // only set as amAdmin when HAS_AM_URL
        if (HAS_AM_URL) {
          userStore.amAdmin = true;
        }

        if (to.name === 'Forbidden') {
          next({ path: '/' });
        } else {
          next();
        }
      } else {
        axios.all([
          getManagedResource(authentication.data.authorization.component.split('/')[1], authenticationId),
          // using claims.sub as userSearchAttribute in case AM doesn't match UUID
          getUserInfo(userStore.userSearchAttribute),
        ]).then((results) => {
          userStore.setUserDetails(results[0].data);
          userStore.amRoles = results[1].data.roles;

          if ((userStore.amRoles.includes('ui-global-admin') || userStore.amRoles.includes('ui-realm-admin')) || (userStore.idmRoles.includes('internal/role/openidm-admin') || userStore.idmRoles.includes('openidm-admin'))) {
            if (to.name === 'Forbidden') {
              next({ path: '/' });
            } else {
              next();
            }
          } else {
            // if the to object is already forbidden, we have already seen it, and need to redirect to enduser
            // because the user has signed out and tried to sign back in with same creds
            if (to.name === 'Forbidden') {
              window.location.href = process.env.VUE_APP_ENDUSER_URL;
            }
            next({ name: 'Forbidden' });
          }
        },
        () => {
          notify({
            group: 'AdminMessage',
            type: 'danger',
            text: i18n.global.t('application.errors.failedLogout'),
          });
        });
      }
    });
  } else {
    next();
  }
});

const loadApp = () => {
  // set the serverConfig with a timeout of 60 seconds this is required because the default timeout is 5 seconds and some trees can take longer to load
  // @see IAM-6840
  Config.set({
    serverConfig: {
      baseUrl: getFQDN(`${process.env.VUE_APP_AM_URL}/`),
      timeout: JAVASCRIPT_SDK_TIMEOUT,
    },
  });

  const app = createApp(App);
  app.use(router);
  app.use(store);
  app.use(pinia);
  app.use(i18n);
  app.use(BootstrapVue);
  app.use(Notifications);
  app.use(Vue3Sanitize, baseSanitizerConfig);
  router.isReady().then(() => app.mount('#appRoot'));

  if (window.Cypress) {
    // only available during E2E tests
    window.app = app;
  }
};

const startApp = () => {
  const promises = [
    getUiConfig(),
    HAS_AM_URL ? getRealmConfigs() : null,
  ];

  Promise.all(promises)
    .then((results) => {
      const uiConfig = results[0];
      // Get & set locales
      const { locales } = getAllLocales(uiConfig.data.configuration);
      setLocales(i18n, locales);
      document.getElementsByTagName('html')[0].setAttribute('lang', i18n.global.locale);

      // Set config to store
      store.commit('SharedStore/setUiConfig', uiConfig.data);

      if (results[1]) {
        const realmData = results[1].data;
        // Set realm data
        if (realmData.result[0].name === '/') {
          realmData.result[0].name = 'root';
        }
        store.commit('setRealms', realmData.result);
      }
    })
    .then(() => overrideTranslations(getFQDN(process.env.VUE_APP_IDM_URL), i18n, 'admin'))
    .finally(() => {
      loadApp();
    });
};

const addAppAuth = () => {
  const AM_URL = store.state.SharedStore.amBaseURL;
  const urlParams = new URLSearchParams(window.location.search);
  const originalLoginRealm = localStorage.getItem('originalLoginRealm');
  const pageLoadUrlRealm = urlParams.get('realm');

  /**
   * If there is an originalLoginRealm here it's because the page was refreshed while in a sub-realm.
   * In this case we want to set the realm used to build the realmPath below to the originally logged in realm.
   * If we don't do this all the appAuthClient config settings will be different from when the page was orignially
   * logged in which causes REST calls to fail and breaks logout because it tries to logout from the wrong realm.
   * The second option of pageLoadUrlRealm pulls the realm if specified in the url. This is important for realm admin
   * login, as the root realm default does not allow realm-admins to log in to their realm.
   * The third option starts as root within cloud at this point in the flow, and then changes to the current realm.
  */
  const realm = originalLoginRealm || pageLoadUrlRealm || store.state.realm;

  let clickSession;
  let keypressSession;
  let pageFocus;
  let realmPath = '';

  // Fraas environments should keep the realm path as root at this point
  if (realm !== '/' && realm !== 'root' && !store.state.isFraas) {
    store.commit('setRealm', realm);

    if (realm.startsWith('/')) {
      realmPath = `realms/root/realms/${realm.substring(1)}/`;
    } else {
      realmPath = `realms/root/realms/${realm}/`;
    }
  }

  const commonSettings = {
    clientId: store.state.idmClientID,
    authorizationEndpoint: process.env.VUE_APP_AUTHORIZATION_ENDPOINT || `${AM_URL}/oauth2/${realmPath}authorize`,
  };

  const resourceServers = {
    [store.state.idmUploadURL]: 'fr:idm:*',
    [store.state.idmExportURL]: 'fr:idm:*',
    [store.state.SharedStore.fraasLoggingKeyURL]: 'openid',
    [store.state.SharedStore.idmBaseURL]: 'fr:idm:*',
    [store.state.SharedStore.analyticsURL]: 'fr:idm:*',
  };

  if (store.state.isFraas) {
    resourceServers[store.state.SharedStore.fraasPromotionUrl] = 'fr:idc:promotion:*';
    resourceServers[`${store.state.SharedStore.fraasEnvironmentUrl}/custom-domains`] = 'fr:idc:custom-domain:*';
    resourceServers[`${store.state.SharedStore.fraasEnvironmentUrl}/certificates`] = 'fr:idc:certificate:*';
    resourceServers[`${store.state.SharedStore.fraasEnvironmentUrl}/csrs`] = 'fr:idc:certificate:*';
    resourceServers[`${store.state.SharedStore.fraasEnvironmentUrl}/secrets`] = 'fr:idm:*';
    resourceServers[`${store.state.SharedStore.fraasEnvironmentUrl}/variables`] = 'fr:idm:*';
    resourceServers[`${store.state.SharedStore.fraasEnvironmentUrl}/count`] = 'fr:idc:esv:*';
    resourceServers[`${store.state.SharedStore.fraasEnvironmentUrl}/sso-cookie`] = 'fr:idc:sso-cookie:*';
    resourceServers[`${store.state.SharedStore.fraasEnvironmentUrl}/startup`] = 'fr:idm:*';
    resourceServers[`${store.state.SharedStore.fraasEnvironmentUrl}/release`] = 'fr:idc:release:*';
    resourceServers[store.state.SharedStore.fraasFederationUrl] = 'fr:idc:federation:*';
    resourceServers[`${store.state.SharedStore.fraasEnvironmentUrl}/content-security-policy`] = 'fr:idc:content-security-policy:*';
  }

  if (store.state.SharedStore.governanceEnabled) {
    resourceServers[store.state.SharedStore.igaApiUrl] = 'fr:iga:*';
    resourceServers[store.state.SharedStore.igaOrchestrationApiUrl] = 'fr:idc:analytics:*';
  }

  if (store.state.SharedStore.autoAccessEnabled) {
    resourceServers[store.state.SharedStore.autoAccessJasUrl] = 'fr:autoaccess:*';
    resourceServers[store.state.SharedStore.autoAccessApiUrl] = 'fr:autoaccess:*';
  }

  if (store.state.SharedStore.autoReportsEnabled || store.state.SharedStore.autoAccessEnabled) {
    resourceServers[store.state.SharedStore.autoAccessReportsUrl] = 'fr:idc:analytics:*';
  }

  if (store.state.SharedStore.wsfedEnabled) {
    resourceServers[store.state.SharedStore.pingFederateUrl] = 'fr:idc:ws:admin';
  }

  AppAuthHelper.init({
    clientId: commonSettings.clientId,
    authorizationEndpoint: commonSettings.authorizationEndpoint,
    tokenEndpoint: process.env.VUE_APP_TOKEN_ENDPOINT || `${AM_URL}/oauth2/${realmPath}access_token`,
    revocationEndpoint: process.env.VUE_APP_REVOCATION_ENDPOINT || `${AM_URL}/oauth2/${realmPath}token/revoke`,
    endSessionEndpoint: process.env.VUE_APP_END_SESSION_ENDPOINT || `${AM_URL}/oauth2/${realmPath}connect/endSession`,
    identityProxyPreference: 'XHR',
    resourceServers,
    tokensAvailableHandler: (claims) => {
      const sub = parseSub(claims);
      const userStore = useUserStore(pinia);
      userStore.userSearchAttribute = sub;

      if (sub !== 'amadmin') {
        determineAdminPrivileges(sub).then((adminPrivileges) => {
          userStore.privileges = adminPrivileges;
        });
      }

      const sessionCheck = new SessionCheck({
        clientId: commonSettings.clientId,
        opUrl: commonSettings.authorizationEndpoint,
        subject: claims.sub,
        invalidSessionHandler() {
          window.logout(false);
        },
        sessionClaimsHandler(newClaims) {
          if (claims.auth_time !== newClaims.auth_time || claims.realm !== newClaims.realm) {
            this.invalidSessionHandler();
          }

          /**
           * Check that the originalLoginRealm session variable is set.
           * If not set it so we know what realm to use for logout.
          */
          if (!localStorage.getItem('originalLoginRealm')) {
            // if the realm comes in as '/' save it as 'root'
            localStorage.setItem('originalLoginRealm', (realm === '/') ? 'root' : realm);
          }
        },
        cooldownPeriod: 5,
      });
      const triggerSession = () => {
        sessionCheck.triggerSessionCheck();
      };
      /**
       * If the originalLoginRealm differs from the realm from the initial load of the page it's
       * because the page was refreshed while in a subrealm. In this case set the app's realm back to
       * the one signified in the url params.
       */
      if (originalLoginRealm && pageLoadUrlRealm && originalLoginRealm !== pageLoadUrlRealm) {
        setRealmUrl(pageLoadUrlRealm);
        store.commit('setRealm', pageLoadUrlRealm);
      }
      // When isFraas never allow access to the root realm
      if (store.state.isFraas && (realm === '/' || realm === 'root' || realm === '/root')) {
        // if the pageLoadUrlRealm is '/' or 'root' use the default fraas realm otherwise use the one passed in via the url
        const fraasRealm = (!pageLoadUrlRealm || pageLoadUrlRealm === '/' || pageLoadUrlRealm === 'root' || pageLoadUrlRealm === '/root') ? store.state.fraasDefaultRealm : pageLoadUrlRealm;
        setRealmUrl(fraasRealm);
        store.commit('setRealm', fraasRealm);
      }

      // check the validity of the session immediately
      triggerSession();

      // check with every route change thru router
      router.beforeEach((to, from, next) => {
        triggerSession();
        next();
      });

      // check with every captured event
      document.removeEventListener('click', clickSession);
      clickSession = document.addEventListener('click', debounce(triggerSession, 100));
      document.removeEventListener('keypress', keypressSession);
      keypressSession = document.addEventListener('keypress', debounce(triggerSession, 100));
      document.removeEventListener('focusin', pageFocus);
      pageFocus = document.addEventListener('focusin', debounce(triggerSession, 100));

      if (store.state.SharedStore.fraasPromotionUrl) {
        // If promotions are allowed on tenant, check for authentication tokens
        // for the upper tenant. If they exist, we need to reinitialise
        // appAuthHelper for the upper tenant
        checkForUpperTenantAuthentication().then((tokensExist) => {
          if (tokensExist) {
            // Tokens for upper tenant exist, attempt to initiate appAuthHelper
            addAppAuthHelperConfigForSecondaryTenant(store.state.SharedStore.fraasPromotionEgressUrl, () => startApp());
          } else {
            // Continue without initialising appAuthHelper for upper tenant
            startApp();
          }
        });
      } else {
        startApp();
      }
    },
  }).then(
    // In this application, we want tokens immediately, before any user interaction is attempted
    () => AppAuthHelper.getTokens(),
  );

  // trigger logout from anywhere in the SPA by calling this global function
  window.logout = (clearHash = true) => {
    const loginRealm = localStorage.getItem('originalLoginRealm');
    /**
     * If there is an originalLoginRealm and that realm is different from the current realm
     * we need to set store.state.realm to it's original state so we can log out properly.
     */
    if (loginRealm) {
      if (store.state.realm !== loginRealm) {
        setRealmUrl(loginRealm);
        store.commit('setRealm', loginRealm);
      }
      localStorage.removeItem('originalLoginRealm');
    }

    // stop the router from watching hash changes so no ugly redirects happen whilst logging out
    router.listening = false;

    // clear hash so user is not directed to previous hash on subsequent logins
    if (clearHash) window.location.hash = '';

    // This will also logout of the upper tenant if the user has authenticated there
    AppAuthHelper.logout().then(() => window.location.reload());
  };
};
store.commit('setEnvironment', process.env);
store.commit('SharedStore/setBaseURLs', process.env);
store.commit('SharedStore/setCurrentPackage', 'admin');
store.commit('SharedStore/setFeatureFlags', process.env);

// Import the routes js file. The route file defaults to routes.platform.js
// but can be overridden using the VUE_APP_ROUTES_FILE environment
// variable. The file extension is included here so webpack can anticipate
// which files to include.
try {
  const routePath = store.state.routesFile;
  const defaultRoutes = require(`../config/routes/${routePath}.js`); // eslint-disable-line
  // TODO: Make separate autoaccess routes file so the component imports don't actually work if there is no auto access enabled?
  // If workforce is disabled, use the OAuth Clients view instead of Applications- but keep the same url
  if (!store.state.SharedStore.workforceEnabled) {
    const appRoute = defaultRoutes.default.find((route) => route.path === '/applications').children[0]; // the first child route is Applications
    appRoute.component = () => import('./views/Applications/ListOAuthClients');
  }
  defaultRoutes.default.forEach(router.addRoute);
} catch (error) {
  console.error(i18n.global.t('application.errors.errorLoadingRoutes'), error); // eslint-disable-line
}

addAppAuth();
