/**
 * Copyright 2021-2024 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import { generateAmApi } from '@forgerock/platform-shared/src/api/BaseApi';
import store from '@/store';

const getScriptApiConfig = () => ({
  path: (store.state.realm === 'root') ? '' : store.state.realm,
  apiVersion: 'protocol=2.0,resource=1.0',
});

const globalConfigScriptApi = {
  path: 'global-config/services/',
  apiVersion: 'protocol=2.0,resource=1.0',
};

/**
  * Gets all scripts, subject to the passed query parameters and whether or not only javascript scripts are allowed.
  *
  * @param {Object} params an object containing query parameters to use in the request (eg. for pagination).
  * @param {Array} supportedScriptTypes the script types to query for.
  * @param {boolean} returnOnlyJavascript whether the request should filter to only return Javascript scripts.
  *
  * @returns {Promise} a promise that resolves to an object containing an array of script objects
  */
export function getScripts(params, supportedScriptTypes, returnOnlyJavascript = false) {
  const extraQueries = [];
  // Set up the query to restrict the returned script types to the supportedScriptTypes
  const legacyScripts = Object.values(supportedScriptTypes).map((st) => st.legacyContext).filter((lc) => lc !== undefined).join('" OR context eq "');
  const nextGenScripts = Object.values(supportedScriptTypes).map((st) => st.nextGenContext).filter((ngc) => ngc !== undefined).join('" OR context eq "');
  extraQueries.push(`(((evaluatorVersion eq "V1_0") AND (context eq "${legacyScripts}")) OR ((evaluatorVersion eq "V2_0") AND (context eq "${nextGenScripts}")))`);
  if (returnOnlyJavascript) {
    // Set up the query to limit the returned script languages to Javascript
    extraQueries.push('language co "JAVASCRIPT"');
  }

  if (params._queryFilter && params._queryFilter !== '') {
    params._queryFilter = `${params._queryFilter} AND ${extraQueries.join(' AND ')}`;
  } else {
    params._queryFilter = extraQueries.join(' AND ');
  }

  return generateAmApi(getScriptApiConfig()).get(
    '/scripts',
    {
      withCredentials: true,
      params,
    },
  );
}

/**
  * Gets a script for the passed ID
  *
  * @returns {Promise} a promise that resolves to an object containing an array of script objects
  */
export function getScriptById(scriptId) {
  return generateAmApi(getScriptApiConfig()).get(
    `/scripts/${scriptId}`,
    { withCredentials: true },
  );
}

/**
  * Deletes a script by the passed ID
  * @param {String} scriptId the ID of the script to delete
  *
  * @returns {Promise} a promise which resolves to the delete response
  */
export function deleteScriptById(scriptId) {
  return generateAmApi(getScriptApiConfig()).delete(
    `/scripts/${scriptId}`,
    { withCredentials: true },
  );
}

/**
 * Creates an AM script based on the passed data
 * @param {Object} scriptData the script to create.
 * @param {String} scriptData.name - The name of the script to create.
 * @param {String} scriptData.description - The description of the script to create.
 * @param {String} scriptData.context - The context (type) of the script to create.
 * @param {String} scriptData.language - The language of the script to create.
 * @param {String} scriptData.script - The Base64 encoded UTF-8 representation of the script content for the script to create.
 */
export function createScript(scriptData) {
  return generateAmApi(getScriptApiConfig()).post(
    '/scripts/?_action=create',
    scriptData,
    { withCredentials: true },
  );
}

/**
 * Updates an existing script in AM.
 * @param {Object} scriptData the data for the script to be updated.
 */
export function updateScript(scriptData) {
  return generateAmApi(getScriptApiConfig()).put(
    `scripts/${scriptData._id}`,
    scriptData,
    { withCredentials: true },
  );
}

/**
 * Gets the scripting contexts, which contains mappings from script types to their default scripts in AM.
 *
 * @returns {Promise} a promise which resolves to the scripting contexts
 */
export function getScriptingContexts() {
  return generateAmApi(globalConfigScriptApi).get(
    'scripting/contexts?_queryFilter=true',
    { withCredentials: true },
  );
}

/**
 * Gets the scripting context for a given script type, which contains mappings
 * from script types to their default scripts in AM as well as script bindings.
 *
 * @returns {Promise} a promise which resolves to the contexts for the given
 * script type
 */
export function getScriptingContextByType(scriptType) {
  return generateAmApi(getScriptApiConfig()).get(
    `contexts/${scriptType}`,
    { withCredentials: true },
  );
}

/**
 * Validates an AM script based on the passed data
 * @param {Object} scriptData the script to validate
 * @param {String} scriptData.language - The language of the script to validate.
 * @param {String} scriptData.script - The Base64 encoded UTF-8 representation of the script content for the script to validate.
 * @returns {Promise} a promise which resolves with a validation success or failure key
 */
export function validateScript(scriptData) {
  return generateAmApi(getScriptApiConfig()).post(
    '/scripts/?_action=validate',
    scriptData,
    { withCredentials: true },
  );
}
