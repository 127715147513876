/**
 * Copyright 2019-2024 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import { clone } from 'lodash';
import dayjs from 'dayjs';

// Default date range for calendar
const dateRange = { endDate: dayjs(), startDate: dayjs().subtract(30, 'day') };

const defaultState = {
  allJourneys: [],
  barCharts: [],
  categoryIds: [],
  dateRange,
  loading: false,
  loaded: false,
  loadedJourneys: [],
  journeyIds: [],
  realm: '',
  selectedAllJourneys: [],
  selectedCategories: [],
  selectedJourneys: [],
  timestamp: '',
};

const getters = {
  allJourneys: (state) => state.allJourneys,
  barCharts: (state) => state.barCharts,
  categoryIds: (state) => state.categoryIds,
  dateRange: (state) => state.dateRange,
  journeyIds: (state) => state.journeyIds,
  loading: (state) => state.loading,
  loaded: (state) => state.loaded,
  loadedJourneys: (state) => state.loadedJourneys,
  realm: (state) => state.realm,
  selectedAllJourneys: (state) => state.selectedAllJourneys,
  selectedCategories: (state) => state.selectedCategories,
  selectedJourneys: (state) => state.selectedJourneys,
  timestamp: (state) => state.timestamp,
};

const mutations = {
  setAllJourneys(state, value) {
    state.allJourneys = clone(value);
  },
  setBarCharts(state, value) {
    state.barCharts = clone(value);
  },
  setCategoryIds(state, value) {
    state.categoryIds = clone(value);
  },
  setDateRange(state, value) {
    state.dateRange = clone(value);
  },
  setJourneyIds(state, value) {
    state.journeyIds = clone(value);
  },
  setLoading(state, value) {
    state.loading = clone(value);
  },
  setLoaded(state, value) {
    state.loaded = clone(value);
  },
  setLoadedJourneys(state, value) {
    state.loadedJourneys = clone(value);
  },
  setRealm(state, value) {
    state.realm = clone(value);
  },
  setSelectedAllJourneys(state, value) {
    state.selectedAllJourneys = clone(value);
  },
  setSelectedCategories(state, value) {
    state.selectedCategories = clone(value);
  },
  setSelectedJourneys(state, value) {
    state.selectedJourneys = clone(value);
  },
  setTimestamp(state, value) {
    state.timestamp = clone(value);
  },
};

const actions = {
  createBarChartData({ commit, state }) {
    const currentJourneyData = state.loadedJourneys.filter((eventItems) => !eventItems.compare && state.selectedJourneys.includes(eventItems.id));
    const selectedAllJourneys = [];
    currentJourneyData.forEach((journey, i) => {
      selectedAllJourneys[i] = { name: journey.eventType.substring(0, journey.eventType.length - 7), bars: [] };
      journey.data.forEach((item) => {
        item.forEach((day) => {
          if (day.timestamp === state.timestamp) {
            if (item[0].resultKey === 'successCount') {
              selectedAllJourneys[i].bars.push({
                color: 'blue', name: journey.eventType.substring(0, journey.eventType.length - 7), type: 'success', value: day.value,
              });
            } else {
              selectedAllJourneys[i].bars.push({
                color: 'red', name: journey.eventType.substring(0, journey.eventType.length - 7), type: 'failed', value: day.value,
              });
            }
          }
        });
      });
    });
    commit('setBarCharts', selectedAllJourneys);
  },
};

export default {
  actions,
  getters,
  mutations,
  state: defaultState,
};
