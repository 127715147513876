/**
 * Copyright 2021-2024 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import { generateFraasEnvironmentApi } from '@forgerock/platform-shared/src/api/BaseApi';

const requestConfig = {
  headers: {
    'Content-Type': 'application/json',
    'Accept-API-Version': 'protocol=1.0,resource=1.0',
  },
  withCredentials: true,
};
/**
 * Gets the existing tenant region info
 *
 * @returns {Promise} Returns a promise that resolves to an object containing tenant-info
 */
export function getTenantInfo() {
  return generateFraasEnvironmentApi().get('/info', requestConfig);
}

/**
 * Gets the tenants release info
 *
 * @returns {Promise} Returns a promise that resolves to an object containing release info
 */
export function getTenantReleaseInfo() {
  return generateFraasEnvironmentApi().get('/release', requestConfig);
}
