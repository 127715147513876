/**
 * Copyright 2022-2024 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

import { cloneDeep } from 'lodash';
import { generateAmApi } from '@forgerock/platform-shared/src/api/BaseApi';
import apiUtils from '@forgerock/platform-shared/src/api/utils/apiUtils';

const apiVersion = 'protocol=2.0,resource=1.0';
const circlesOfTrustApiRequest = (realm) => {
  const configPath = (realm && realm.length)
    ? apiUtils.getRealmConfigPath(realm) : apiUtils.getCurrentRealmConfigPath();
  const requestConfig = {
    path: `${configPath}/federation/circlesoftrust`,
    apiVersion,
  };
  return generateAmApi(requestConfig);
};

/**
  * Gets all circles of trust
  * @returns {Promise} a promise that resolves to an array of circle of trust objects
  */
export function getAllCirclesOfTrust() {
  return circlesOfTrustApiRequest().get(
    '?_queryFilter=true',
    { withCredentials: true },
  );
}
/**
  * Gets a circle of trust for the passed ID.
  * @param {string} circleOfTrustId circle of trust id
  * @param {String} realm the realm to get the circle of trust from
  * @returns {Promise} a promise that resolves to an object containing a circle of trust config
  */
export function getCircleOfTrustById(circleOfTrustId, realm) {
  return circlesOfTrustApiRequest(realm).get(
    `/${circleOfTrustId}`,
    { withCredentials: true },
  );
}
/**
 * Creates a circle of trust
 * @param {Object} circleOfTrustConfig the circle of trust to create
 * @param {String} realm the realm to create the circle of trust in
 * @returns {Promise}
 */
export function createCircleOfTrust(circleOfTrustConfig, realm) {
  const postData = cloneDeep(circleOfTrustConfig);
  delete postData._rev;
  return circlesOfTrustApiRequest(realm).post(
    '/?_action=create',
    postData,
    { withCredentials: true },
  );
}
/**
  * Updates a circle of trust for the passed ID.
  * @param {string} circleOfTrustId circle of trust id
  * @param {Object} circleOfTrustConfig the circle of trust to update
  * @param {String} realm the realm to update the circle of trust in
  * @returns {Promise} a promise that resolves to an object containing a circle of trust
  */
export function updateCircleOfTrust(circleOfTrustId, circleOfTrustConfig, realm) {
  const postData = cloneDeep(circleOfTrustConfig);
  delete postData._rev;
  return circlesOfTrustApiRequest(realm).put(
    `/${circleOfTrustId}`,
    postData,
    { withCredentials: true },
  );
}
