/**
 * Copyright 2022-2024 ForgeRock AS. All Rights Reserved
 *
 * Use of this code requires a commercial software license with ForgeRock AS
 * or with one of its affiliates. All use shall be exclusively subject
 * to such license between the licensee and ForgeRock AS.
 */

/* eslint-disable no-restricted-syntax */

import {
  difference,
  isEqual,
  map,
  omit,
  reject,
} from 'lodash';
import {
  deleteManagedResource,
  getManagedResourceList,
  putManagedResource,
} from '@forgerock/platform-shared/src/api/ManagedResourceApi';
import { putConfig, getConfig } from '@forgerock/platform-shared/src/api/ConfigApi';
import {
  createGatewayOrAgent,
  getGatewayOrAgent,
  updateGatewayOrAgent,
} from '@forgerock/platform-shared/src/api/AgentsApi';
import {
  createSaml2Entity,
  getSaml2EntityByIdAndLocation,
  updateSaml2Entity,
} from '@/api/Saml2Api';
import {
  getCircleOfTrustById,
  updateCircleOfTrust,
} from '@/api/CirclesOfTrustApi';
import i18n from '@/i18n';

const workforceCircleOfTrustId = 'FR_COT';
const tenantLocationPlaceholder = '<TENANT-LOCATION>';

/**
 * Adds saml entities as trusted providers in the workforce circle of trust for a realm
 * @param {Array} samlEntityIdsToAddToFrCOT array of IDs that should be included in the COT
 * @param {String} realm the realm to update the COT for
 */
async function addSamlEntitiesToWorkforceCircleOfTrustForRealm(samlEntityIdsToAddToFrCOT, realm) {
  const trustedProviders = [];

  // Get the existing trusted providers (if any)
  try {
    const currentTenantWorkforceCOT = await getCircleOfTrustById(workforceCircleOfTrustId, realm);
    trustedProviders.concat(currentTenantWorkforceCOT.data.trustedProviders);
  } catch (error) {
    // If the GET fails we will just add the providers we have
  }

  // Add the new entities as trusted providers in the circle of trust
  samlEntityIdsToAddToFrCOT.forEach((entityId) => {
    const formattedIdForCot = `${entityId}|saml2`;
    // only add the provider if it doesn't already exist in the circle of trust
    if (!trustedProviders.includes(formattedIdForCot)) {
      trustedProviders.push(formattedIdForCot);
    }
  });

  // Update the realms circle of trust with the new trusted providers
  await updateCircleOfTrust(workforceCircleOfTrustId, { status: 'active', trustedProviders }, realm);
}

/**
 * Adds or updates selected "dynamic" (aka "custom") app data and creates/updates/deletes "static" app (aka apps with static config attached) on the current tenant
 * @param {Object} dynamicAppsToAddOrUpdate the "dynamic" applications to add/update on this tenant. Array objects contain the app and saml/oauth data
 * @param {Object} staticApps the "static" applications to create/update/delete on this tenant.
 */
export async function processPromotedApps(dynamicAppsToAddOrUpdate, staticApps) {
  for await (const realm of ['alpha', 'bravo']) {
    const dynamicAppsToAddOrUpdateForRealm = dynamicAppsToAddOrUpdate[realm];
    const realmApplicationResourceName = `${realm}_application`;
    // accumulate COT changes per realm to make all at once
    const samlEntityIdsToAddToFrCOT = [];

    for await (const appData of dynamicAppsToAddOrUpdateForRealm) {
      // PUT the promoted application onto the current tenant (creates if it doesn't exist and overwrites if it does)
      putManagedResource(realmApplicationResourceName, appData.app._id, appData.app);

      // If the app has an OAuth2 client, add or update it in the current tenant
      if (appData.OAuth2Client) {
        try {
          // If the client already exists, update it
          await getGatewayOrAgent('oauth2', appData.OAuth2Client._id, realm);
          await updateGatewayOrAgent('oauth2', appData.OAuth2Client._id, appData.OAuth2Client, realm);
        } catch {
          // Otherwise create it
          await createGatewayOrAgent('oauth2', appData.OAuth2Client._id, appData.OAuth2Client, realm);
        }
      }
    }

    for await (const appData of staticApps[realm].appsToUpdateOrCreate) {
      // PUT the promoted application onto the current tenant (creates if it doesn't exist and overwrites if it does)
      putManagedResource(realmApplicationResourceName, appData.app._id, appData.app);
      // If the app has any SAML data, add or update it in the current tenant
      if (appData.saml2Entities) {
        for await (const samlLocation of ['hosted', 'remote']) {
          const privateId = samlLocation === 'hosted' ? appData.app.ssoEntities.idpPrivateId : appData.app.ssoEntities.spPrivateId;
          const entityLocation = samlLocation === 'hosted' ? appData.app.ssoEntities.idpLocation : appData.app.ssoEntities.spLocation;
          const samlEntityForLocation = appData.saml2Entities[samlLocation];

          if (samlEntityForLocation) {
            try {
              // If the SAML entity already exists, update it
              await getSaml2EntityByIdAndLocation(privateId, entityLocation, false, realm);

              await updateSaml2Entity(privateId, entityLocation, samlEntityForLocation, realm);
            } catch {
              // Otherwise create it
              await createSaml2Entity(samlEntityForLocation, realm);
            }

            // Note the entity ID that should be present in or added to the workforce circle of trust
            samlEntityIdsToAddToFrCOT.push(samlEntityForLocation.entityId);
          }
        }
      }
    }
    // Add new entities to the workforce circle of trust for this realm, creating it if it doesn't exist
    if (samlEntityIdsToAddToFrCOT.length) {
      await addSamlEntitiesToWorkforceCircleOfTrustForRealm(samlEntityIdsToAddToFrCOT, realm);
    }

    for await (const appData of staticApps[realm].appsToDelete) {
      // PUT the promoted application onto the current tenant (creates if it doesn't exist and overwrites if it does)
      deleteManagedResource(realmApplicationResourceName, appData._id);
    }
  }
}

/**
 * Retrieves all app dynamic data for the current tenant across alpha and bravo realms,
 * including extended data like saml entities and OAuth2 clients. Gathered data is stripped of tenant specific
 * information like URLs and _rev properties, so that it can be easily imported into other environments
 *
 * @returns {Object} all application dynamic data split by realm
 */
export async function gatherApplicationData() {
  const applicationData = {};
  const currentTenantUrl = window.location.host;

  for await (const realm of ['alpha', 'bravo']) {
    applicationData[realm] = {
      applications: [],
    };

    const realmApplicationResourceName = `${realm}_application`;
    const resourcesResponse = await getManagedResourceList(realmApplicationResourceName, { queryFilter: 'true' });
    const applicationResources = resourcesResponse.data?.result || [];

    for await (const applicationResource of applicationResources) {
      const appData = {
        app: applicationResource,
      };

      // Load any SAML data for this app and add it to the appData
      if (applicationResource?.ssoEntities?.idpPrivateId || applicationResource?.ssoEntities?.spPrivateId) {
        const {
          idpPrivateId,
          idpLocation,
          spPrivateId,
          spLocation,
        } = applicationResource.ssoEntities;
        appData.saml2Entities = {};

        if (idpPrivateId) {
          const hosted = await getSaml2EntityByIdAndLocation(idpPrivateId, idpLocation, false, realm);
          appData.saml2Entities.hosted = omit(hosted.data, '_rev');
        }
        if (spPrivateId) {
          const remote = await getSaml2EntityByIdAndLocation(spPrivateId, spLocation, true, realm);
          appData.saml2Entities.remote = omit(remote.data, '_rev');
        }
      }

      // Load any OAuth2 client associated with the app and add it to the appData
      if (applicationResource?.ssoEntities?.oidcId) {
        const { data: OAuth2Client } = await getGatewayOrAgent('oauth2', applicationResource.ssoEntities.oidcId, realm);
        delete OAuth2Client._rev;
        appData.OAuth2Client = OAuth2Client;
      }

      applicationData[realm].applications.push(appData);
    }
  }

  // Replace all references to the current tenant with a placeholder to be replaced in upper environments
  return JSON.parse(JSON.stringify(applicationData).replaceAll(currentTenantUrl, tenantLocationPlaceholder));
}

/**
 * Gather application dynamic data for the current tenant and store it in IDM config so that it can be promoted
 */
export async function storeAppDynamicDataInConfig() {
  const applicationData = await gatherApplicationData();
  await putConfig('appDynamicData', applicationData);
}

/**
 * Retrieves application dynamic data from IDM config, updating it for the current tenant
 *
 * @returns {Object} application dynamic data from IDM config, updated for the current tenant. Split by realm
 */
export async function retrieveAppDynamicDataFromConfig() {
  const currentTenantUrl = window.location.host;
  try {
    let { data } = await getConfig('appDynamicData');
    delete data._id;
    // Replace all instances to the tenant location placeholder with the current tenants location
    data = JSON.parse(JSON.stringify(data).replaceAll(tenantLocationPlaceholder, currentTenantUrl));
    return data;
  } catch (e) {
    const data = { alpha: { applications: [] }, bravo: { applications: [] } };
    return data;
  }
}

/**
 * Determines if the passed application dynamic data contains information for any apps
 * @param {Object} applicationData application dynamic data, split by realm
 *
 * @returns {Boolean} whether the passed data contains any apps
 */
export function appDataContainsApps(applicationData) {
  let appCount = 0;
  Object.values(applicationData).forEach((appArray) => {
    appCount += appArray.applications.length;
  });
  return appCount > 0;
}

/**
 * Formats application dynamic data so it can be rendered as entries in a promotions report
 * @param {Object} applicationData application dynamic data, split by realm
 *
 * @returns {Array} application data formatted into an array that can be consumed by the PromotionsReport component
 */
export function formatApplicationDataForPromotionReport(applicationData) {
  const formattedAppData = [];
  Object.entries(applicationData).forEach(([realm, appArray]) => {
    appArray.applications.forEach((appData) => {
      formattedAppData.push({
        name: appData.app.name,
        realm,
        category: i18n.global.t('applications.promotion.dynamicDataCategory'),
      });
    });
  });

  return formattedAppData;
}

/**
 * Compares two apps by all properties except their _rev
 * @param {Object} app1 app managed object data
 * @param {Object} app2 app managed object data
 *
 * @returns {Boolean} whether the apps are equal
 */
function appsAreEqual(app1, app2) {
  return isEqual(omit(app1, '_rev'), omit(app2, '_rev'));
}

/**
 * Looks at app and determines if it is an app that relies on static config (aka idm config)
 *
 * @param {Object} app app managed object data
 *
 * @returns {Boolean} whether the app static
 */
function appHasStaticConfig(app) {
  const dynamicAppTypes = ['native', 'web', 'service'];
  if (dynamicAppTypes.includes(app.templateName)) {
    return false;
  }
  return true;
}

/**
 * Splits the passed promoted app data into apps which do and don't have dynamic data on the current tenant
 * @param {Object} promotedAppData promoted application dynamic data, split by realm
 *
 * @returns {Object} promoted application data, split into apps that do and don't have dynamic data in the current tenant
 */
export async function splitPromotedAppsByChangeType(promotedAppData) {
  const appsByType = {};

  for await (const realm of ['alpha', 'bravo']) {
    appsByType[realm] = {
      dynamicAppsNotInCurrentEnv: [],
      dynamicAppsInBothEnvs: [],
      staticAppsToUpdateOrCreate: [],
      staticAppsToDelete: [],
    };
    const realmApplicationResourceName = `${realm}_application`;

    // Load apps from the current tenant so we can compare them to the promoted apps
    const resourcesResponse = await getManagedResourceList(realmApplicationResourceName, { queryFilter: 'true' });
    const currentTenantApps = resourcesResponse.data.result;
    const currentTenantAppsById = currentTenantApps.reduce((appsById, appData) => {
      appsById[appData._id] = appData;
      return appsById;
    }, {});
    const currentTenantAppIds = Object.keys(currentTenantAppsById);
    // Get a list of apps that exist on this tenant but not on the lower tenant
    const tenantAppDifferences = difference(currentTenantAppIds, map(promotedAppData[realm].applications, 'app._id')).map((id) => currentTenantAppsById[id]);
    // Set staticAppsToDelete with only static apps from tenantAppDifferences
    appsByType[realm].staticAppsToDelete = reject(tenantAppDifferences, (app) => !appHasStaticConfig(app));

    promotedAppData[realm].applications.forEach((promotedApp) => {
      const { app: { _id: promotedAppId } } = promotedApp;

      if (currentTenantAppIds.includes(promotedAppId)) {
        // App was promoted and is also present in the current environment
        // Only present for loading if the app has changed
        if (!appsAreEqual(promotedApp.app, currentTenantAppsById[promotedAppId])) {
          if (appHasStaticConfig(promotedApp.app)) {
            appsByType[realm].staticAppsToUpdateOrCreate.push(promotedApp);
          } else {
            appsByType[realm].dynamicAppsInBothEnvs.push(promotedApp);
          }
        }
      } else if (appHasStaticConfig(promotedApp.app)) {
        appsByType[realm].staticAppsToUpdateOrCreate.push(promotedApp);
      } else {
        // app is not present in current environment, present it for loading
        appsByType[realm].dynamicAppsNotInCurrentEnv.push(promotedApp);
      }
    });
  }

  return appsByType;
}
